<template>
	<div>
		<fieldset class="border">
			<legend class="border">Détails de l'évènement</legend>
			<b>Créateur:</b> 
			{{ currentEventModal.details.nomCreateur }} <br/>
			<b>Nom Client:</b> 
			{{ currentEventModal.details.nomClient }} <br/>
			<b>Personne affectée:</b> 
			{{ personneEvent(currentEventModal.resourceId) }} <br/>
			<b>Numéro du stock:</b> 
			{{ currentEventModal.details.numStock }} <br/>
			<b>Commence le :</b> 
			{{ dateDebut }} à {{ heureDebut }} <br/>
			<b>Fini le:</b> 
			{{ dateFin }} à {{ heureFin }} <br/>
			<b>Catégories:</b>
			<template v-for="item in currentEventModal.details.categories">
				{{ item }}
			</template> 
			<br/>
			<b>Adresse:</b> 
			{{ currentEventModal.details.adresse }} <br/>
			<b>Commentaire:</b> 
			{{ currentEventModal.details.commentaire }} <br/>
			<b>Evènement validé:</b> 
			{{ currentEventModal.details.valide }} <br/>
			<b>Fichiers:</b>
			<br/>
			<template v-for="item in currentEventModal.details.filePath">
				<a v-text="item.name" :href="'/api/downloadFile/'+item.name" download></a> <br/>
			</template>
		</fieldset>

		<fieldset class="border">
			<legend class="border">Ajouter un fichier</legend>
			<input id="file" ref="file" size="small" type="file" @change="handleFileUpload"/>
            <button v-on:click="uploadfile(currentEventModal, file)">Envoyer</button>
		</fieldset>

		<fieldset v-if="!currentEventModal.details.valide && currentEventModal.details.idCreateur == idUser || !currentEventModal.details.valide && typeUser == 'admin'" class="border">
			<legend class="border">Editer l'évènement</legend>
			<el-form ref="form" :model="currentEventModal.details">
				<el-form-item label="Nom du client">
					<el-input v-model="currentEventModal.details.nomClient" @change="changeLabel"></el-input>
				</el-form-item>

				<el-form-item label="Personne affectée">
					<el-select v-model="currentEventModal.resourceId" filterable placeholder="Choisissez une personne">
						<el-option v-for="item in ressources" :key="item.id" :label="item.title" :value="item.id">
						</el-option>
					</el-select>
				</el-form-item>

				<el-form-item label="Numéro du stock">
					<el-input v-model="currentEventModal.details.numStock" @change="changeLabel"></el-input>
				</el-form-item>

				<el-form-item label="Catégories">
					<el-select v-model="currentEventModal.details.categories" filterable multiple @change="changeCategories" collapse-tags style="margin-left: 20px;" placeholder="Choisir">
						<el-option v-for="item in optionsCategories" :key="item.value" :label="item.label" :value="item.value">
						</el-option>
					</el-select>
				</el-form-item>

				<el-form-item label="Journée entière">
					<el-switch v-model="currentEventModal.allDay" @change="changeAllday"></el-switch>
				</el-form-item>

				<el-form-item label="Commence le">
					<el-col :span="8">
						<el-date-picker type="date" placeholder="Choisissez une date" v-model="currentEventModal.details.dateDebut" @change="changeDate" style="width: 100%;"></el-date-picker>
					</el-col>
					<el-col class="line" :span="2" v-if="!currentEventModal.allDay">-</el-col>
					<el-col :span="8" v-if="!currentEventModal.allDay">
						<el-time-picker placeholder="Sélectionnez l'horaire" v-model="currentEventModal.details.heureDebut" @change="changeDate" style="width: 100%;"></el-time-picker>
					</el-col>
				</el-form-item>
				
				<el-form-item label="Termine le">
					<el-col :span="8">
						<el-date-picker type="date" placeholder="Choisissez une date" v-model="dateFinReel" @change="changeDate" style="width: 100%;"></el-date-picker>
					</el-col>
					<el-col class="line" :span="2" v-if="!currentEventModal.allDay">-</el-col>
					<el-col :span="8" v-if="!currentEventModal.allDay">
						<el-time-picker placeholder="Sélectionnez l'horaire" v-model="currentEventModal.details.heureFin" @change="changeDate" style="width: 100%;"></el-time-picker>
					</el-col>
				</el-form-item>

				<el-form-item label="Adresse">
					<el-input v-model="currentEventModal.details.adresse"></el-input>
				</el-form-item>

				<el-form-item label="Commentaire">
					<el-input type="textarea" resize="none" rows="5" v-model="currentEventModal.details.commentaire"></el-input>
				</el-form-item>

				<el-form-item label="Evènement validé">
					<el-switch v-model="currentEventModal.details.valide" @change="changeValide"></el-switch>
				</el-form-item>

				<el-form-item>
					<el-row class="actionEdit">
						<el-button type="primary" @click="updateCurrentEvent" round>Mettre à jour</el-button>
						<el-button type="danger" @click="removeEvent(event.id)" round>Supprimer</el-button>
					</el-row>
				</el-form-item>
			</el-form>
		</fieldset>
		<fieldset v-else-if="currentEventModal.details.valide && currentEventModal.details.idCreateur == idUser  || currentEventModal.details.valide && typeUser == 'admin'" class="border">
			<legend class="border">Editer l'évènement</legend>
			<el-form ref="form" :model="currentEventModal.details">
				<el-form-item label="Nom du client">
					<el-input disabled v-model="currentEventModal.details.nomClient"></el-input>
				</el-form-item>

				<el-form-item label="Personne affectée">
					<el-select disabled v-model="currentEventModal.resourceId" filterable placeholder="Choisissez une personne">
						<el-option v-for="item in ressources" :key="item.id" :label="item.title" :value="item.id">
						</el-option>
					</el-select>
				</el-form-item>

				<el-form-item label="Numéro du stock">
					<el-input disabled v-model="currentEventModal.details.numStock"></el-input>
				</el-form-item>

				<el-form-item label="Catégories">
					<el-select disabled v-model="currentEventModal.details.categories" filterable multiple @change="changeCategories" collapse-tags style="margin-left: 20px;" placeholder="Choisir">
						<el-option v-for="item in optionsCategories" :key="item.value" :label="item.label" :value="item.value">
						</el-option>
					</el-select>
				</el-form-item>

				<el-form-item label="Journée entière">
					<el-switch disabled v-model="currentEventModal.allDay" @change="changeAllday"></el-switch>
				</el-form-item>

				<el-form-item label="Commence le">
					<el-col :span="8">
						<el-date-picker disabled type="date" placeholder="Choisissez une date" v-model="currentEventModal.details.dateDebut" @change="changeDate" style="width: 100%;"></el-date-picker>
					</el-col>
					<el-col class="line" :span="2" v-if="!currentEventModal.allDay">-</el-col>
					<el-col :span="8" v-if="!currentEventModal.allDay">
						<el-time-picker disabled placeholder="Sélectionnez l'horaire" v-model="currentEventModal.details.heureDebut" @change="changeDate" style="width: 100%;"></el-time-picker>
					</el-col>
				</el-form-item>
				
				<el-form-item label="Termine le">
					<el-col :span="8">
						<el-date-picker disabled type="date" placeholder="Choisissez une date" v-model="currentEventModal.details.dateFin" @change="changeDate" style="width: 100%;"></el-date-picker>
					</el-col>
					<el-col class="line" :span="2" v-if="!currentEventModal.allDay">-</el-col>
					<el-col :span="8" v-if="!currentEventModal.allDay">
						<el-time-picker disabled placeholder="Sélectionnez l'horaire" v-model="currentEventModal.details.heureFin" @change="changeDate" style="width: 100%;"></el-time-picker>
					</el-col>
				</el-form-item>

				<el-form-item label="Adresse">
					<el-input disabled v-model="currentEventModal.details.adresse"></el-input>
				</el-form-item>

				<el-form-item label="Commentaire">
					<el-input disabled type="textarea" resize="none" rows="5" v-model="currentEventModal.details.commentaire"></el-input>
				</el-form-item>

				<el-form-item label="Evènement validé">
					<el-switch v-model="currentEventModal.details.valide" @change="changeValide"></el-switch>
				</el-form-item>

				<el-form-item>
					<el-row class="actionEdit">
						<el-button type="primary" @click="updateCurrentEvent" round>Mettre à jour</el-button>
						<el-button type="danger" @click="removeEvent(event.id)" round>Supprimer</el-button>
					</el-row>
				</el-form-item>
			</el-form>
		</fieldset>
	</div>
</template>

<script>
import moment from "moment"
import script from '../../../script/planning-script'
export default {
	data () {
		return {
			file:'',
			dateDebut: null,
			dateFin: null,
			heureDebut: null,
			heureFin: null,
			dateFinReel: null,
			optionsCategories:[
				{
					value: 'sav',
					label: 'SAV'
				},
				{
					value: 'demos',
					label: 'Démos'
				},
				{
					value: 'receptionsTechniques',
					label: 'Réceptions techniques'
				},
				{
					value: 'miseEnService',
					label: 'Mise en service'
				},
				{
					value: 'formation',
					label: 'Formation'
				},
				{
					value: 'vae',
					label: 'VAE (Ventes Aux Enchères)'
				},
				{
					value: 'absence',
					label: 'Absence'
				},
				{
					value: 'conges',
					label: 'Congés'
				},
				{
					value: 'ferie',
					label: 'Fériés si pas automatiques'
				},
				{
					value: 'chargement',
					label: 'Chargement'
				},
				{
					value: 'dechargement',
					label: 'Déchargement'
				},
				{
					value: 'preparationMachine',
					label: 'Préparation des machines'
				},
				{
					value: 'formationPI',
					label: 'Formation du personnel interne'
				},
				{
					value: 'rdvCom',
					label: 'RDV commercial'
				},
				{
					value: 'reunion',
					label: 'Réunion'
				},
				{
					value: 'reglementAttente',
					label: 'Règlement en attente'
				},
				{
					value: 'enAttenteDeLivraison',
					label: 'En attente de livraison de pièces détachées'
				}
			]
		}
	},
	mounted(){
		if(this.$store.state.currentEvent.allDay){
			this.dateFinReel = moment(this.$store.state.currentEvent.details.dateFin).subtract(1, 'days')
		}else{
			this.dateFinReel = moment(this.$store.state.currentEvent.details.dateFin)
		}
	},
	computed:{
		currentEventModal(){
			if(this.$store.state.currentEvent.allDay){
				this.dateDebut = moment(this.$store.state.currentEvent.details.dateDebut).format('L')
				this.dateFin = moment(this.$store.state.currentEvent.details.dateFin).format('L')
				this.heureDebut = moment(this.$store.state.currentEvent.details.dateDebut).format('HH:mm')
				this.heureFin = moment(this.$store.state.currentEvent.details.dateFin).format('HH:mm')
			} else {
				this.dateDebut = moment(this.$store.state.currentEvent.details.dateDebut).format('L')
				this.dateFin = moment(this.$store.state.currentEvent.details.dateFin).format('L')
				this.heureDebut = moment(this.$store.state.currentEvent.details.heureDebut).format('HH:mm')
				this.heureFin = moment(this.$store.state.currentEvent.details.heureFin).format('HH:mm')
			}
			
			return this.$store.state.currentEvent
		},
		ressources(){
			return this.$store.state.ressources
		},
		idUser(){
			return this.$store.state.info.id
		},
		typeUser(){
			return this.$store.state.info.type
		}
	},
	props:{
		event: Object
	},
	methods:{
		toasterError: script.toasterError,
		formatDate(date){
			var options = {
				year:"numeric",
				month:"2-digit",
				day:"2-digit"
			}
			let string = date.toLocaleDateString("ko-KR", options).replace(/\. /g, "-")
			return string.substr(0, string.length-1)
		},
		updateCurrentEvent(){
			let find = this.$store.state.ressources.filter(ressource => ressource.id == this.currentEventModal.resourceId)
			let start = moment(this.currentEventModal.start)
			let end = moment(this.currentEventModal.end)
			if(start.diff(end)>0){
				this.toasterError(`La date de fin doit être après la date de début`,`Changement de date`)
			}else{
				this.$store.commit('updateEvent', this.currentEventModal)
				this.updateEvent(this.currentEventModal,find[0].memberDetail.email, this.$store.state.info.prenom + " " + this.$store.state.info.nom)
				this.$modal.hideAll()
			}
		},
		deleteEvent: script.deleteEvent,
		removeEvent(id){
			let find = this.$store.state.ressources.filter(ressource => ressource.id == this.currentEventModal.resourceId)
			if (window.confirm("Supprimer cet évènement?")) { 
				this.deleteEvent(id,this.currentEventModal,find[0].memberDetail.email, this.$store.state.info.prenom + " " + this.$store.state.info.nom)
				this.$modal.hideAll()
			}
		},
		personneEvent(id){
			let personne = this.$store.state.ressources.filter(ressource => ressource.id == id)
			return personne[0].memberDetail.firstname + " " + personne[0].memberDetail.lastname
		},
		uploadfile: script.uploadfile,
		changeLabel(){
			this.currentEventModal.title = "? " + this.currentEventModal.details.nomClient + " " + this.currentEventModal.details.numStock + " ?"
		},
		changeValide(){
			if(this.currentEventModal.details.valide){
				this.currentEventModal.title = this.currentEventModal.details.nomClient + " " + this.currentEventModal.details.numStock
			} else {
				this.currentEventModal.title = "? " + this.currentEventModal.details.nomClient + " " + this.currentEventModal.details.numStock + " ?"
			}
		},
		changeAllday(){
			this.currentEventModal.details.allDay = this.currentEventModal.allDay
			if(this.currentEventModal.allDay){
				let end = moment(this.currentEventModal.details.dateDebut).add({hours: 24})
				this.currentEventModal.details.heureDebut = this.currentEventModal.details.dateDebut
				this.currentEventModal.details.heureFin = end.format()
				this.currentEventModal.details.dateFin = end.format()
				this.currentEventModal.end = end.format()
			}
		},
		changeCategories(){
			const categoriesColors = {
				sav : "#FF0000",
				demos: "#00E0FF",
				receptionsTechniques: "#00FF00",
				miseEnService: "#FFFF00",
				formation: "#FFFF00",
				vae: "#FF9EC7",
				absence: "#FF7000",
				conges: "#7D7D7D",
				ferie: "#7D7D7D",
				chargement: "#0000FF",
				dechargement: "#0000FF",
				preparationMachine: "#854000",
				formationPI: "#B200FF",
				rdvCom: "#005400",
				reunion: "#C8A41D",
				reglementAttente: "#FF1493",
				enAttenteDeLivraison: "#8A9917",
				default: "#7D7D7D",
			}
			let categoriesLength = this.currentEventModal.details.categories.length
			var backgroundColor = "default"
			var borderColor = "default"
			if(categoriesLength){
				if(categoriesLength>1){
					backgroundColor = this.currentEventModal.details.categories[0]
					borderColor = this.currentEventModal.details.categories[1]
				}else{
					backgroundColor = this.currentEventModal.details.categories[0]
					borderColor = this.currentEventModal.details.categories[0]
				}
			}
			this.currentEventModal.backgroundColor = categoriesColors[backgroundColor]
			this.currentEventModal.borderColor = categoriesColors[borderColor]
		},
		updateEvent: script.updateEvent,
		changeDate(){
			let start = moment(this.currentEventModal.details.dateDebut).add({hours: moment(this.currentEventModal.details.heureDebut).hour(), minutes: moment(this.currentEventModal.details.heureDebut).minute()})
			let end = moment(this.dateFinReel).add({hours: moment(this.currentEventModal.details.heureFin).hour(), minutes: moment(this.currentEventModal.details.heureFin).minute()})
			if(start.diff(end)>0){
				this.toasterError(`La date de fin doit être après la date de début`,`Changement de date`)
			}else{
				if(this.currentEventModal.allDay){
					this.currentEventModal.start = start.format()
					this.currentEventModal.end = end.add({hours: 24}).format()
					this.currentEventModal.details.dateFin = this.currentEventModal.end
				}else{
					this.currentEventModal.start = start.format()
					this.currentEventModal.end = end.format()
					this.currentEventModal.details.dateFin = this.currentEventModal.end
				}
			}
		},
		downloadItem: script.downloadItem,
		handleFileUpload(){
			this.file = this.$refs.file.files[0]
		},
	}
}
</script>

<style>
.actionEdit{
	padding: 1em;
}
</style>