<template>
	<div id="calendar">
		<div class="search">
			<el-input placeholder="Entrer un mot clé" v-model="search" class="search input-with-select">
				<el-button slot="append" icon="el-icon-search" @click="searchEvent"></el-button>
			</el-input>
			<el-select v-model="categories" class="filter" multiple collapse-tags placeholder="Filtrer les catégories">
				<el-option v-for="item in filterCategories" :key="item.value" :label="item.label" :value="item.value">
				</el-option>
			</el-select>
			<el-select v-model="ressourcesFilter" class="filter" multiple collapse-tags placeholder="Filtrer les ressources">
				<el-option v-for="item in filterRessources" :key="item.value" :label="item.label" :value="item.value">
					<span style="float: left">{{ item.label }}</span>
					<span style="float: right; color: #8492a6; font-size: 13px">{{ item.groupe }}</span>
				</el-option>
			</el-select>
			<el-button @click="clearFilter">Effacer tous les filtres</el-button>
		</div>
		<FullCalendar :options="calendarOptions" ref="calendar"></FullCalendar>
		<div class="legende">
			<template v-for="item in categoriesColors">
				<div class="legendeItem" :key="item.couleur">
					<div :style="{height:'25px',width:'25px',background :item.couleur, margin: '0 0.5em 0 0'}">
					</div>
					{{item.label}}
				</div>
			</template>
		</div>
		<modal-containers />
	</div>
</template>

<script>
import moment from "moment"
// import "@fullcalendar/core/main.css"
import "@fullcalendar/daygrid/main.css"
import "@fullcalendar/timegrid/main.css"
import "@fullcalendar/timeline/main.css"
import "@fullcalendar/resource-timeline/main.css"


// import moment from "moment"
import FullCalendar from '@fullcalendar/vue'
// import DayGridPlugin from '@fullcalendar/daygrid'
import TimeGridPlugin from '@fullcalendar/timegrid'
import ResourceTimelinePlugin from '@fullcalendar/resource-timeline'
import InteractionPlugin from '@fullcalendar/interaction'
// import ListPlugin from '@fullcalendar/list'
import '@fullcalendar/core/locales/fr'
import scriptPlanning from '../../../script/planning-script'


import EventModal from './EventModal'
import AddEvent from './AddEvent'
import Recherche from './Recherche'

export default {
	components:{
		FullCalendar
	},
	data () {
		return {
			search:"",
			ressourcesFilter:[],
			categories:[],
			filterCategories:[
				{
					value: 'sav',
					label: 'sav'
				},
				{
					value: 'demos',
					label: 'démos'
				},
				{
					value: 'receptionsTechniques',
					label: 'Réceptions techniques'
				},
				{
					value: 'miseEnService',
					label: 'Mise en service'
				},
				{
					value: 'formation',
					label: 'formation'
				},
				{
					value: 'vae',
					label: 'vae'
				},
				{
					value: 'absence',
					label: 'absence'
				},
				{
					value: 'conges',
					label: 'congés'
				},
				{
					value: 'ferie',
					label: 'ferié'
				},
				{
					value: 'chargement',
					label: 'chargement'
				},
				{
					value: 'dechargement',
					label: 'déchargement'
				},
				{
					value: 'preparationMachine',
					label: 'Préparation machine'
				},
				{
					value: 'formationPI',
					label: 'Formation interne'
				},
				{
					value: 'rdvCom',
					label: 'RDV commercial'
				},
				{
					value: 'reunion',
					label: 'réunion'
				},
				{
					value: 'reglementAttente',
					label: 'Règlement en attente'
				},
				{
					value: 'enAttenteDeLivraison',
					label: 'En attente de livraison de pièces détachées'
				}
			],
			categoriesColors: [
				{
					label : "SAV",
					couleur : "#FF0000",
				},
				{
					label: "Démos",
					couleur: "#00E0FF",
				},
				{
					label: "Réceptions techniques",
					couleur: "#00FF00",
				},
				{
					label: "Mise en service",
					couleur: "#FFFF00",
				},
				{
					label: "Formation",
					couleur: "#FFFF00",
				},
				{
					label: "VAE",
					couleur: "#FF9EC7",
				},
				{
					label: "Absence",
					couleur: "#FF7000",
				},
				{
					label: "Congés",
					couleur: "#7D7D7D",
				},
				{
					label: "Férié",
					couleur: "#7D7D7D",
				},
				{
					label: "Chargement",
					couleur: "#0000FF",
				},
				{
					label: "Déchargement",
					couleur: "#0000FF",
				},
				{
					label: "Préparation machine",
					couleur: "#854000",
				},
				{
					label: "Formation interne",
					couleur: "#B200FF",
				},
				{
					label: "Rendez-vous commercial",
					couleur: "#005400",
				},
				{
					label: "Réunion",
					couleur: "#C8A41D",
				},
				{
					label: "Règlement en attente",
					couleur: "#FF1493",
				},
				{
					label: "En attente de livraison de pièces détachées",
					couleur: "#8A9917",
				}
			],
		}
	},
	methods:{
		toasterSuccess: scriptPlanning.toasterSuccess,
		toasterError: scriptPlanning.toasterError,
		getEvents: scriptPlanning.getEvents,
		handleSelect(info){
			let find = this.$store.state.ressources.filter(ressource => ressource.id == this.$store.state.info.id)
			if(find.length){
				if(find[0].memberDetail.groupe.idGroupe != "5" || this.$store.state.info.type == "admin"){
					this.$modal.show(AddEvent,{
						event: info
					})
				}else{
					this.toasterError(`Vous n'avez pas le droit de créer un évènement`,`Création d'évènement`)
				}
			}else{
				if(this.$store.state.info.type == "admin"){
					this.$modal.show(AddEvent,{
						event: info
					})
				}
			}
			
		},
		handleEventsSet(events) {
			this.events = events
		},
		handleEventClick(arg){
			this.$store.commit("getEvent",{id:arg.event.id})
			this.$modal.show(EventModal,{
				event: arg.event
			})
		},
		loadRessource: scriptPlanning.loadRessource,
		handleEventDrop(info){
			let eventDetails = this.$store.state.events.filter(event => event.id == info.event.id)
			if(!eventDetails[0].details.valide){
				if(eventDetails[0].details.idCreateur == this.$store.state.info.id || this.$store.state.info.type == "admin"){
					if (!confirm("Valider le changement?")) {
						info.revert()
					} else{
						return this.resizeDropEvent(info,eventDetails[0].resourceId)
					}
				} else {
					this.toasterError(`Vous n'êtes pas le créateur de l'évènement`,`Changement de l'évènement`)
					info.revert()
				}
			}else{
				this.toasterError(`L'évènement est validé`,`Changement de l'évènement`)
				info.revert()
			}
		},
		resizeDropEvent(info,resourceId){
			let find = this.$store.state.ressources.filter(ressource => ressource.id == resourceId)
			if(find.length){
				this.$store.commit('updateEventResizeDrop', info)
				let event = this.$store.state.events.filter(event => event.id == info.event.id)
				this.updateEvent(event[0],find[0].memberDetail.email, this.$store.state.info.prenom + " " + this.$store.state.info.nom)
			}else{
				this.toasterError(`L'utilisateur de ne peut pas être notifié par email`,`Changement de l'évènement`)
				info.revert()
			}
		},
		searchEvent(){
			this.$modal.show(Recherche,{
				search: this.search
			})
		},
		updateEvent: scriptPlanning.updateEvent,
		clearFilter(){
			this.categories = []
			this.ressourcesFilter = []
		},
		initialLoad(){
			let calendarApi = this.$refs.calendar.getApi()
			this.loadRessource()
			let sql = "datep >= '" + moment(calendarApi.view.currentStart).format() + "' AND datep <= '" + moment(calendarApi.view.currentEnd).format() + "'"
			this.getEvents(sql)
		},
	},
	mounted: function() {
		this.initialLoad()
	},
	computed:{
		ressources(){
			if(this.ressourcesFilter.length){
				let ressources = []
				this.$store.state.ressources.forEach(ressource => {
					if(this.ressourcesFilter.includes(ressource.id)){
						let elements = ressources.filter(element => element.id == ressource.id)
						if(elements.length == 0){
							ressources.push(ressource)
						}
					}
				})
				return ressources
			} else {
				return this.$store.state.ressources
			}
		},
		filterRessources(){
			let ressources = []
			this.$store.state.ressources.forEach(ressource => {
				ressources.push({
					label:ressource.title,
					value:ressource.id,
					groupe:ressource.memberDetail.groupe.name,
				})
			})
			return ressources
		},
		events(){
			if(this.categories.length){
				let events = []
				this.$store.state.events.forEach(event => {
					this.categories.forEach(categorie => {
						if(event.details.categories.includes(categorie)){
							let elements = events.filter(element => element.id == event.id)
							if(elements.length == 0){
								events.push(event)
							}
						}
					})
				})
				return events
			} else {
				return this.$store.state.events
			}
		},
		config(){
			return {
				...this.calendarOptions
			}
		},
		calendarOptions(){
			return {
				locale:"fr",
				plugins: [
					TimeGridPlugin,
					ResourceTimelinePlugin,
					InteractionPlugin,
				],
				schedulerLicenseKey: '0693624034-fcs-1604439302',
				headerToolbar:{
					left:'prev today next',
					center:'title',
					right: 'resourceTimelineDay,resourceTimelineWeek,resourceTimelineMonth,resourceTimelineYear'
				},
				// buttonText:{
					// today: 'Aujourd\'hui',
					// month: 'Mois',
					// week: 'Semaine',
					// day: 'Jour',
					// year: 'Année',
					// list: 'Liste'
				// },
				resourceAreaColumns: [
					{
						field: 'title',
						headerContent: 'Ressources'
					}
				],
				themeSystem: 'litera',
				selectable: true,
				editable:true,
				weekends: true,
				firstDay: 1,
				initialView: 'resourceTimelineWeek',
				dayMaxEvents: true,
				selectMirror: true,
				select: this.handleSelect,
				eventsSet: this.handleEventsSet,
				events: this.events,
				eventClick: this.handleEventClick,
				eventDrop: this.handleEventDrop,
				eventResize: this.handleEventDrop,
				weekNumbers: true,
				weekText: "S",
				nowIndicator: true,
				resourceOrder: '-groupId',
				resourceGroupField:'groupId',
				resources: this.ressources,
				slotDuration: "12:00",
				customButtons: { 
					prev: { // this overrides the prev button
						text: "PREV", 
						click: () => {
							let calendarApi = this.$refs.calendar.getApi()         
							calendarApi.prev()
							let sql = "datep >= '" + moment(calendarApi.view.currentStart).format() + "' AND datep <= '" + moment(calendarApi.view.currentEnd).format() + "'"
							this.getEvents(sql)
						}
					},
					next: { // this overrides the next button
						text: "NEXT",
						click: () => {
							let calendarApi = this.$refs.calendar.getApi()
							calendarApi.next()
							let sql = "datep >= '" + moment(calendarApi.view.currentStart).format() + "' AND datep <= '" + moment(calendarApi.view.currentEnd).format() + "'"
							this.getEvents(sql)
						}
					},
					today: { // this overrides the today button
						text: "Aujourd'hui",
						click: () => {
							let calendarApi = this.$refs.calendar.getApi()
							calendarApi.today()
							let sql = "datep >= '" + moment(calendarApi.view.currentStart).format() + "' AND datep <= '" + moment(calendarApi.view.currentEnd).format() + "'"
							this.getEvents(sql)
						}
					},
					resourceTimelineDay: { // this overrides the day button
						text: "Jour",
						click: () => {
							let calendarApi = this.$refs.calendar.getApi()
							calendarApi.changeView("resourceTimelineDay")
							let sql = "datep >= '" + moment(calendarApi.view.currentStart).format() + "' AND datep <= '" + moment(calendarApi.view.currentEnd).format() + "'"
							this.getEvents(sql)
						}
					},
					resourceTimelineWeek: { // this overrides the week button
						text: "Semaine",
						click: () => {
							let calendarApi = this.$refs.calendar.getApi()
							calendarApi.changeView("resourceTimelineWeek")
							let sql = "datep >= '" + moment(calendarApi.view.currentStart).format() + "' AND datep <= '" + moment(calendarApi.view.currentEnd).format() + "'"
							this.getEvents(sql)
						}
					},
					resourceTimelineMonth: { // this overrides the month button
						text: "Mois",
						click: () => {
							let calendarApi = this.$refs.calendar.getApi()
							calendarApi.changeView("resourceTimelineMonth")
							let sql = "datep >= '" + moment(calendarApi.view.currentStart).format() + "' AND datep <= '" + moment(calendarApi.view.currentEnd).format() + "'"
							this.getEvents(sql)
						}
					},
					resourceTimelineYear: { // this overrides the year button
						text: "Année",
						click: () => {
							let calendarApi = this.$refs.calendar.getApi()
							calendarApi.changeView("resourceTimelineYear")
							let sql = "datep >= '" + moment(calendarApi.view.currentStart).format() + "' AND datep <= '" + moment(calendarApi.view.currentEnd).format() + "'"
							this.getEvents(sql)
						}
					}
				}
			}
		}
	}
}
</script>

<style>
#calendar{
	height:100%;
	padding: 2em;
}

#calendar .fc{
	height:96%;
}

#calendar .fc .fc-view-harness{
	height: 100% !important;
}

#calendar .fc-h-event{
	border-width: 4px;
}

.search{
	padding-bottom: 1em;
}

#calendar thead .fc-scrollgrid-section-header:last-child tbody tr:first-child{
	font-size: 22px;
}

#calendar thead .fc-scrollgrid-section-header:last-child tbody tr:last-child{
	font-size: 13px;
}

#calendar thead .fc-scrollgrid-section-header:last-child tbody tr:nth-child(2){
	font-size: 14px;
}

.legende{
	display: flex;
	flex-wrap: wrap;
}

.legendeItem{
	display: flex;
	padding: 1em;
	flex: 1 20%;
}

.search{
	padding-bottom: 0.5em;
}

.filter{
	padding: 0 0.5em 0.5em 0;
}
</style>