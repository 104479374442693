<template>
  <div id="router">    
    <router-view></router-view>
  </div>
</template>

<script>


export default {
  data() {
    return {
      post: {},
    }
  },
  mounted:function(){
     
  },
  methods: {

  },
  components: {
  }
  
}
</script>

<style>
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

#router{
	height: 100%;
}
</style>
