<template>
    <div style="text-align:center; width:50%; margin:auto;">
        <img src="https://fmo-planning.ideal2s.net/img/LOGO-BANDEAU-FMO.png" alt="FMO" style="width:50%;"/>
    </div>
</template>

<script>
import script from '../../script/script.js';

export default {
  data() {
    return {
      
    }
  },
  mounted:function(){
    this.verifyRight();
  },
  computed:{
  },
  methods: {
    verifyRight: script.verifyRight
  }
  
}
</script>

<style>

</style>
