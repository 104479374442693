<template>
  <div>
	<b-navbar toggleable="md" type="dark">
		<b-navbar-toggle v-if="$isMobile()" target="navbar"></b-navbar-toggle>
		<b-collapse id="navbar" is-nav>
			<b-navbar-nav class="ml-auto">
				<b-nav-item-dropdown v-if="$isMobile()" text="page">
					<b-dropdown-item v-for="(el, i) in verticalMenu" :key="i" :to="el.href"><span :class="el.icon"></span> {{el.title}}</b-dropdown-item>
				</b-nav-item-dropdown>

				<b-nav-item size="md" class="mr-sm-2">{{ time }}</b-nav-item>
				<b-nav-item :to="'#'" size="sm" class="mr-sm-2"><span class="far fa-user"></span> {{ post.prenom+" "+post.nom }}</b-nav-item>
				<b-nav-item v-on:click="disconnect()" size="sm" class="mr-sm-2" right><span class="fas fa-sign-out-alt" ></span><template v-if="$isMobile()"> Déconnexion</template></b-nav-item>
			</b-navbar-nav>
		</b-collapse>
	</b-navbar>

    <!-- Ajout rapide de contact -->
    <b-modal size="sm" ref="newContactRapide" id="newContactRapide" title="Ajouter un contact" hide-footer>
      <b-form ref="formContactRapide">
        <b-form-text><b>Tiers :</b></b-form-text>
        <model-select :options="optionsTiers" v-model="formContactRapide.socid" placeholder="Chercher un tiers" required size="sm"></model-select>
        <b-form-text style="margin-top:10px;"><b>Nom :</b></b-form-text>
        <b-form-input v-model="formContactRapide.lastname" size="sm" id="lastname" required></b-form-input>
        <b-form-text style="margin-top:10px;"><b>Prénom :</b></b-form-text>
        <b-form-input v-model="formContactRapide.firstname" size="sm" required></b-form-input>
        <b-form-text style="margin-top:10px;">Fonction :</b-form-text>
        <b-form-input v-model="formContactRapide.poste" size="sm"></b-form-input>
        <b-form-text style="margin-top:10px;"><b>Téléphone Pro. :</b></b-form-text>
        <b-form-input v-model="formContactRapide.phone_pro" size="sm" required></b-form-input>
        <b-form-text style="margin-top:10px;"><b>Téléphone Mobile :</b></b-form-text>
        <b-form-input v-model="formContactRapide.phone_mobile" size="sm"></b-form-input>
        <b-form-text style="margin-top:10px;"><b>Email :</b></b-form-text>
        <b-form-input v-model="formContactRapide.email" size="sm" required></b-form-input>
        <b-form-text style="margin-top:10px;">Adresse :</b-form-text>
        <b-form-input v-model="formContactRapide.address" size="sm"></b-form-input>
        <b-form-text style="margin-top:10px;">CP :</b-form-text>
        <b-form-input v-model="formContactRapide.zip" size="sm"></b-form-input>
        <b-form-text style="margin-top:10px;">Ville :</b-form-text>
        <b-form-input v-model="formContactRapide.town" size="sm"></b-form-input>
        <b-container fluid>
          <b-row class="text-center">
            <b-col>
              <b-button size="sm" @click="onSubmitContactRapide" variant="success" style="margin-top:10px;">VALIDER</b-button>
              <b-button size="sm" @click="onResetContactRapide" variant="danger" style="margin-top:10px;">RESET</b-button>
            </b-col>
          </b-row>
        </b-container>
      </b-form>
    </b-modal>
  </div>
</template>
<script>
import script from '../../script/script.js';
import { ModelSelect } from 'vue-search-select'
var moment = require('moment');
export default {
  data() {
    return {
      post: {
        prenom: '',
        nom: ''
      },
      active: true,
      time: moment().format('LLLL'),
      intervalTime:null,
      modalAjoutExtranet:{
        title:"Ajout en masse d'utilisateur extranet"
      },
      templates:[],
      templateSelected: null,
      contacts:[],
      selectedContact:null,
      clientsExtranet:{},
      fieldsContacts: [
        { key: 'selected', label: '' },
        { key: 'firstname', label: 'Prénom', sortable: true},
        { key: 'lastname', label: 'Nom',sortable: true},
        { key: 'socname', label: 'Société' ,sortable: true}
      ],
      selectMode: 'multi',
      filterNameTiers:"",
      filterCodeTiers:"",
      filterAddressTiers:"",
      filterZipTiers:"",
      filterTownTiers:"",
      filterPhoneTiers:"",
      filterMailTiers:"",
      filterTypeTiers:"0",
      filterStatusTiers:"0",
      optionType:[
        {value: 0, text:"Type"},
        {value: 8, text:"Particulier"},
        {value: 1, text:"Professionnel"}
      ],
      optionStatus:[
        {value: 0, text:"Statut"},
        {value: 1, text:"Client"},
        {value: 2, text:"Prospect"},
        {value: 3, text:"Fournisseur"},
        {value: 4, text:"Client/Fournisseur"},
        {value: 5, text:"Prospect/Fournisseur"}
      ],
      formContactRapide: {
        socid: '',
        lastname: '',
        firstname: '',
        poste: '',
        phone_pro: '',
        email: '',
        address: '',
        zip: '',
        town: ''
      },
      optionsTiers: []
    }
  },
  beforeCreate(){
    moment.locale('fr');
  },
  mounted(){
    this.loadingInfo();
    this.intervalTime = setInterval(() => this.time = moment().format('LLLL'), 500);
  },
  beforeDestroy(){
    clearInterval(this.intervalTime);
  },
  methods: {
    disconnect: script.disconnect,
    loadingInfo: script.loadingInfo,
    openAjoutExtranet: script.openAjoutExtranet,
    loadingClient: script.loadingClient,
    addMassiveContact: script.addMassiveContact,
    addContact: script.addContact,
    resetModalAjoutExtranet() {
      this.modalAjoutExtranet.title = ''
      this.modalAjoutExtranet.content = ''
    },
    rowSelected(items) {
      this.selectedContact = items
    },
    linkGen (pageNum) {
      return pageNum === 1 ? '?' : `?page=${pageNum}`
    },
    onAccountCreated() {
        this.$refs.newTiers.toggle()
        this.loadingClient()
    },
    onSubmitContactRapide() {
      this.addContact(this.formContactRapide)
      this.$refs.formContactRapide.reset()
      this.$refs.newContactRapide.toggle()
    },

    onResetContactRapide() {
      this.$refs.formContactRapide.reset()
    },
    loadingAllClient: script.loadingAllClient,
    openAjoutContact() {
      this.loadingAllClient()
      this.$refs.newContactRapide.toggle()
	}
  },
  components: {
    ModelSelect
  }
  
}
</script>

<style>
	#navbar .btn-secondary, #navbar .btn-secondary:hover, #navbar .btn-secondary:active, #navbar .btn-secondary:focus, #navbar .btn-secondary:not(:disabled):not(.disabled).active, #navbar .btn-secondary:not(:disabled):not(.disabled):active, #navbar .show>.btn-secondary.dropdown-toggle{
		background-color: transparent;
		border-color: transparent;
		box-shadow: none;
	}

	.navbar-dark .navbar-nav .nav-link {
		color: white;
	}

	.navbar{
		height: 100%;
		background-color:#30519E;
	}

	.dropdown:before, .dropdown.active:before{
		content: '';
	}
</style>

<style scoped>

</style>
