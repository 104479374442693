<template>
	<div>
		<fieldset class="border">
			<legend class="border">Ajout d'un évènement</legend>
			<el-form ref="form" :rules="rules" :model="form">
				<el-form-item label="Nom du client">
					<el-input v-model="form.nomClient"></el-input>
				</el-form-item>

				<el-form-item v-if="this.$store.state.info.type == 'admin'" label="Personne affectée">
					<el-select v-model="form.personneAffecte" multiple multiple-limit="20" filterable placeholder="Choisissez une personne">
						<el-option v-for="item in ressources" :key="item.id" :label="item.title" :value="item.id">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item v-else-if="groupUser == '6'" label="Personne affectée">
					<el-select v-model="form.personneAffecte" multiple multiple-limit="2" filterable placeholder="Choisissez une personne">
						<el-option v-for="item in optionsRessourcesCommercial" :key="item.id" :label="item.title" :value="item.id">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item v-else-if="groupUser == '8'" label="Personne affectée">
					<el-select v-model="form.personneAffecte" multiple multiple-limit="3" filterable placeholder="Choisissez une personne">
						<el-option v-for="item in optionsRessourcesAdministratif" :key="item.id" :label="item.title" :value="item.id">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item v-else-if="groupUser == '9'" label="Personne affectée">
					<el-select v-model="form.personneAffecte" multiple multiple-limit="3" filterable placeholder="Choisissez une personne">
						<el-option v-for="item in optionsRessourcesTechnique" :key="item.id" :label="item.title" :value="item.id">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item v-else label="Personne affectée">
					<el-select v-model="form.personneAffecte" multiple multiple-limit="1" filterable placeholder="Choisissez une personne">
						<el-option v-for="item in optionsRessourcesUser" :key="item.id" :label="item.title" :value="item.id">
						</el-option>
					</el-select>
				</el-form-item>

				<el-form-item label="Numéro du stock">
					<el-input v-model="form.numStock"></el-input>
				</el-form-item>

				<el-form-item label="Catégories">
					<el-select v-model="form.categories" multiple filterable multiple-limit="2" collapse-tags style="margin-left: 20px;" placeholder="Choisir">
						<el-option v-for="item in optionsCategories" :key="item.value" :label="item.label" :value="item.value">
						</el-option>
					</el-select>
				</el-form-item>

				<el-form-item label="Journée entière">
					<el-switch @change="changeAllDay" v-model="form.allDay"></el-switch>
				</el-form-item>

				<el-form-item label="Commence le">
					<el-col :span="8">
						<el-date-picker type="date" placeholder="Choisissez une date" v-model="form.dateDebut" style="width: 100%;"></el-date-picker>
					</el-col>
					<el-col class="line" :span="2" v-if="!form.allDay">-</el-col>
					<el-col :span="8" v-if="!form.allDay">
						<el-time-picker placeholder="Sélectionnez l'horaire" v-model="form.heureDebut" :picker-options="pickerOptions" style="width: 100%;"></el-time-picker>
					</el-col>
				</el-form-item>

				<el-form-item label="Termine le">
					<el-col :span="8">
						<el-date-picker type="date" placeholder="Choisissez une date" v-model="form.dateFin" style="width: 100%;"></el-date-picker>
					</el-col>
					<el-col class="line" :span="2" v-if="!form.allDay">-</el-col>
					<el-col :span="8" v-if="!form.allDay">
						<el-time-picker placeholder="Sélectionnez l'horaire" v-model="form.heureFin" :picker-options="pickerOptions" style="width: 100%;"></el-time-picker>
					</el-col>
				</el-form-item>

				<el-form-item label="Adresse">
					<el-input v-model="form.adresse"></el-input>
				</el-form-item>

				<el-form-item label="Commentaire">
					<el-input type="textarea" resize="none" rows="5" v-model="form.commentaire"></el-input>
				</el-form-item>

				<el-form-item label="Evènement validé">
					<el-switch v-model="form.valide"></el-switch>
				</el-form-item>
				
				<label for="files">Ajouter des fichiers
					<input id="files" ref="files" size="small" type="file" multiple @change="handleFileUpload"/>
				</label>

				<el-form-item>
					<el-button type="primary" @click="addEvent">Créer</el-button>
					<el-button @click="closeEvent">Annuler</el-button>
				</el-form-item>
			</el-form>
		</fieldset>
	</div>
</template>

<script>
import moment from "moment"
import script from '../../../script/planning-script'
export default {
	data () {
		return {
			form: {
				idCreateur:'',
				nomCreateur:'',
				nomClient: '',
				personneAffecte: [],
				numStock: '',
				dateDebut: '',
				dateFin: '',
				heureDebut: '',
				heureFin: '',
				adresse: '',
				commentaire: '',
				categories: [],
				file:[],
				valide: false,
				allDay: '',
				filePath:[],
			},
			optionsCategories:[
				{
					value: 'sav',
					label: 'SAV'
				},
				{
					value: 'demos',
					label: 'Démos'
				},
				{
					value: 'receptionsTechniques',
					label: 'Réceptions techniques'
				},
				{
					value: 'miseEnService',
					label: 'Mise en service'
				},
				{
					value: 'formation',
					label: 'Formation'
				},
				{
					value: 'vae',
					label: 'VAE (Ventes Aux Enchères)'
				},
				{
					value: 'absence',
					label: 'Absence'
				},
				{
					value: 'conges',
					label: 'Congés'
				},
				{
					value: 'ferie',
					label: 'Fériés si pas automatiques'
				},
				{
					value: 'chargement',
					label: 'Chargement'
				},
				{
					value: 'dechargement',
					label: 'Déchargement'
				},
				{
					value: 'preparationMachine',
					label: 'Préparation des machines'
				},
				{
					value: 'formationPI',
					label: 'Formation du personnel interne'
				},
				{
					value: 'rdvCom',
					label: 'RDV commercial'
				},
				{
					value: 'reunion',
					label: 'Réunion'
				},
				{
					value: 'reglementAttente',
					label: 'Règlement en attente'
				},
				{
					value: 'enAttenteDeLivraison',
					label: 'En attente de livraison de pièces détachées'
				}
			],
			pickerOptions:{
				format:'HH:mm'
			},
			rules: {
				nomClient: [
					{ required: true, message: 'Nom de client obligatoire', trigger: 'blur' }
				],
				personneAffecte: [
					{ required: true, message: 'Affectation de personne obligatoire', trigger: 'change' }
				],
				categories: [
					{required: true, message: 'Catégorie obligatoire', trigger: 'change' }
				]
			}
		}
	},
	mounted(){
		this.form.dateDebut = moment(this.event.start).format("YYYY-MM-DD")
		this.form.heureDebut = this.event.start
		this.form.dateFin = moment(this.event.end).format("YYYY-MM-DD")
		this.form.heureFin = this.event.end
		this.form.allDay = this.event.allDay
		if(this.$store.state.info.type == 'admin'){
			this.form.personneAffecte.push(this.event.resource.id)
		}else if(this.$store.state.info.addEventTech){
			let find = this.$store.state.ressources.filter(ressource => ressource.id == this.event.resource.id)
			if(find[0].memberDetail.groupe.idGroupe == "5"){
				this.form.personneAffecte.push(this.event.resource.id)
			}else{
				this.form.personneAffecte.push(this.$store.state.info.id)
			}
		}else{
			this.form.personneAffecte.push(this.$store.state.info.id)
		}
	},
	computed:{
		ressources(){
			return this.$store.state.ressources
		},
		optionsRessourcesCommercial(){
			let ressources = []
			let find = this.$store.state.ressources.filter(ressource => ressource.id == this.$store.state.info.id)
			ressources.push(find[0])
			let findR = ressources.filter(element => element.id == 31)
			if(!findR.length){
				let binome = this.$store.state.ressources.filter(ressource => ressource.id == 31)
				ressources.push(binome[0])
			}
			return ressources
		},
		optionsRessourcesAdministratif(){
			let ressource = []
			let find = this.$store.state.ressources.filter(ressource => ressource.id == this.$store.state.info.id)
			ressource.push(find[0])
			if(this.$store.state.info.addEventTech){
				let findTech = this.$store.state.ressources.filter(ressource => ressource.memberDetail.groupe.idGroupe == "5")
				findTech.forEach(element => {
					ressource.push(element)
				})
			}
			return ressource
		},
		optionsRessourcesTechnique(){
			let ressource = []
			let find = this.$store.state.ressources.filter(ressource => ressource.id == this.$store.state.info.id)
			ressource.push(find[0])
			if(this.$store.state.info.addEventTech){
				let findTech = this.$store.state.ressources.filter(ressource => ressource.memberDetail.groupe.idGroupe == "5")
				findTech.forEach(element => {
					ressource.push(element)
				})
			}
			return ressource
		},
		optionsRessourcesUser(){
			let ressource = []
			let find = this.$store.state.ressources.filter(ressource => ressource.id == this.$store.state.info.id)
			ressource.push(find[0])
			return ressource
		},
		idUser(){
			return this.$store.state.info.id
		},
		nomCreateur(){
			return this.$store.state.info.prenom + " " + this.$store.state.info.nom
		},
		groupUser(){
			let find = this.$store.state.ressources.filter(ressource => ressource.id == this.$store.state.info.id)
			return find[0].memberDetail.groupe.idGroupe
		}
	},
	props:{
		event: Object,
	},
	methods:{
		toasterError: script.toasterError,
		putEvent: script.putEvent,
		formatDate(date){
			var options = {
				year:"numeric",
				month:"2-digit",
				day:"2-digit"
			}
			let string = date.toLocaleDateString("ko-KR", options).replace(/\. /g, "-")
			return string.substr(0, string.length-1)
		},
		addEvent(){
			this.$refs["form"].validate((valid) => {
				if (valid) {
					this.form.idCreateur = this.idUser
					this.form.nomCreateur = this.nomCreateur
					const categoriesColors = {
						sav : "#FF0000",
						demos: "#00E0FF",
						receptionsTechniques: "#00FF00",
						miseEnService: "#FFFF00",
						formation: "#FFFF00",
						vae: "#FF9EC7",
						absence: "#FF7000",
						conges: "#7D7D7D",
						ferie: "#7D7D7D",
						chargement: "#0000FF",
						dechargement: "#0000FF",
						preparationMachine: "#854000",
						formationPI: "#B200FF",
						rdvCom: "#005400",
						reunion: "#C8A41D",
						reglementAttente: "#FF1493",
						enAttenteDeLivraison: "#8A9917",
						default: "#7D7D7D",
					}
					let categoriesLength = this.form.categories.length
					var backgroundColor = "default"
					var borderColor = "default"
					if(categoriesLength){
						if(categoriesLength>1){
							backgroundColor = this.form.categories[0]
							borderColor = this.form.categories[1]
						}else{
							backgroundColor = this.form.categories[0]
							borderColor = this.form.categories[0]
						}
					}

					var textColor = "white"
					if(categoriesColors[backgroundColor] == "#FFFF00"){
						textColor = "black"
					}
					var title = ""
					if(this.form.valide){
						title = this.form.nomClient + ' ' + this.form.numStock
					}else{
						title = "? " + this.form.nomClient + ' ' + this.form.numStock + " ?"
					}

					if(this.form.allDay){
						let end = moment(this.form.dateFin).add({hours: 24})
						this.form.heureDebut = this.form.dateDebut
						this.form.heureFin = end
						this.form.dateFin = end
						this.form.personneAffecte.forEach(element => {
							let eventAgenda = {
								id: '',
								title: title,
								start: this.form.dateDebut,
								end: end.format(),
								allDay: this.form.allDay,
								resourceId:element,
								backgroundColor: categoriesColors[backgroundColor],
								borderColor: categoriesColors[borderColor],
								textColor: textColor,
								details: this.form
							}
							let find = this.$store.state.ressources.filter(ressource => ressource.id == element)
							if(find.length){
								this.putEvent(eventAgenda,"",find[0].memberDetail.email, this.$store.state.info.prenom + " " + this.$store.state.info.nom)
							}else{
								this.toasterError(`L'utilisateur de ne peut pas être notifié par email`,`Changement de l'évènement`)
								this.$modal.hideAll()
								return false
							}
						})
					} else{
						let start = moment(this.form.dateDebut).add({hours: moment(this.form.heureDebut).hour(), minutes: moment(this.form.heureDebut).minute()})
						let end = moment(this.form.dateFin).add({hours: moment(this.form.heureFin).hour(), minutes: moment(this.form.heureFin).minute()})
						this.form.personneAffecte.forEach(element => {
							let eventAgenda = {
								id: '',
								title: title,
								start: start.format(),
								end: end.format(),
								allDay: this.form.allDay,
								resourceId:element,
								backgroundColor: categoriesColors[backgroundColor],
								borderColor: categoriesColors[borderColor],
								textColor: textColor,
								details: this.form
							}
							let find = this.$store.state.ressources.filter(ressource => ressource.id == element)
							if(find.length){
								this.putEvent(eventAgenda,"",find[0].memberDetail.email, this.$store.state.info.prenom + " " + this.$store.state.info.nom)
							}else{
								this.toasterError(`L'utilisateur de ne peut pas être notifié par email`,`Changement de l'évènement`)
								this.$modal.hideAll()
								return false
							}
						})
					}
					this.$modal.hideAll()
				} else {
					return false
				}
			})
		},
		changeAllDay(){
			if(!this.form.allDay){
				this.form.dateFin = this.form.dateDebut
			} else{
				this.form.dateFin = this.event.end
			}
		},
		handleFileUpload(){
			this.form.file = this.$refs.files.files
		},
		closeEvent(){
			this.$modal.hideAll()
		}
	}
}
</script>

<style>
#modals-container .vm--modal{
	overflow: auto;
	padding: 1em;
	top: 20% !important;
    left: 20% !important;
    width: 60% !important;
    height: 60% !important;
}

#modals-container fieldset.border {
    border: 1px groove #ddd !important;
    padding: 0 1.4em 1.4em 1.4em !important;
    margin: 0 0 1.5em 0 !important;
    -webkit-box-shadow:  0px 0px 0px 0px #000;
            box-shadow:  0px 0px 0px 0px #000;
}

#modals-container legend.border {
    font-size: 1.2em !important;
    font-weight: bold !important;
    text-align: left !important;
	width:inherit;
    padding:0 10px;
    border:none !important;
}

#modals-container .control-group {
    display: flex;
}

#modals-container .line{
	text-align: center;
}
</style>