<template>
	<div>
		<el-tabs tab-position="left">
			<el-tab-pane label="Importer" class="importCal">
				<label id="labelFile" for="file">Choisir un fichier ics
					<input id="file" ref="file" size="small" type="file" @change="handleFileUpload"/>
				</label>
				<el-button id="btnImport" size="small" type="success" @click="importCal">Importer le calendrier</el-button>
			</el-tab-pane>
			<el-tab-pane label="Exporter">
				<el-form ref="form" :model="formExport">
					<el-form-item label="Ressource">
						<el-select v-model="formExport.ressources" multiple filterable collapse-tags style="margin-left: 20px;" placeholder="Choisir">
							<el-option v-for="item in ressources" :key="item.memberDetail.id" :label="item.memberDetail.firstname + ' ' + item.memberDetail.lastname" :value="item.memberDetail.id">
							</el-option>
						</el-select>
					</el-form-item>

					<el-form-item label="Catégories">
						<el-select v-model="formExport.categories" multiple filterable collapse-tags style="margin-left: 20px;" placeholder="Choisir">
							<el-option v-for="item in optionsCategories" :key="item.value" :label="item.label" :value="item.value">
							</el-option>
						</el-select>
					</el-form-item>

					<el-form-item label="Nom Client">
						<el-select v-model="formExport.nomClient" multiple filterable collapse-tags style="margin-left: 20px;" placeholder="Choisir">
							<el-option v-for="item in filterNomClient" :key="item.value" :label="item.label" :value="item.value">
							</el-option>
						</el-select>
					</el-form-item>

					<el-form-item label="Numéro de stock">
						<el-select v-model="formExport.numStock" multiple filterable collapse-tags style="margin-left: 20px;" placeholder="Choisir">
							<el-option v-for="item in filterNumStock" :key="item.value" :label="item.label" :value="item.value">
							</el-option>
						</el-select>
					</el-form-item>

					<el-form-item label="Mois">
						<el-select v-model="formExport.mois" multiple filterable collapse-tags style="margin-left: 20px;" placeholder="Choisir">
							<el-option v-for="item in filterMois" :key="item.value" :label="item.label" :value="item.value">
							</el-option>
						</el-select>
					</el-form-item>

					<el-form-item label="Année">
						<el-select v-model="formExport.annee" multiple filterable collapse-tags style="margin-left: 20px;" placeholder="Choisir">
							<el-option v-for="item in filterAnnee" :key="item.value" :label="item.label" :value="item.value">
							</el-option>
						</el-select>
					</el-form-item>

					<el-form-item label="Numéro de semaine">
						<el-select v-model="formExport.numSemaine" multiple filterable collapse-tags style="margin-left: 20px;" placeholder="Choisir">
							<el-option v-for="item in filterSemaines" :key="item.value" :label="item.label" :value="item.value">
							</el-option>
						</el-select>
					</el-form-item>

					<el-form-item label="Équipe">
						<el-select v-model="formExport.equipe" multiple filterable collapse-tags style="margin-left: 20px;" placeholder="Choisir">
							<el-option v-for="item in optionsEquipe" :key="item.value" :label="item.label" :value="item.value">
							</el-option>
						</el-select>
					</el-form-item>

					<el-form-item label="Site">
						<el-select v-model="formExport.site" multiple filterable collapse-tags style="margin-left: 20px;" placeholder="Choisir">
							<el-option v-for="item in optionsSite" :key="item.value" :label="item.label" :value="item.value">
							</el-option>
						</el-select>
					</el-form-item>

					<el-form-item>
						<el-button type="primary" @click="exportCal(formExport)">Exporter sous .csv</el-button>
					</el-form-item>
				</el-form>
			</el-tab-pane>
		</el-tabs>
		
	</div>
</template>

<script>
import moment from "moment"
import script from '../../../script/planning-script'
export default {
	data () {
		return {
			file:"",
			formExport:{
				categories:[],
				ressources:[],
				nomClient:[],
				numStock:[],
				mois:[],
				annee:[],
				numSemaine:[],
				equipe:[],
				site:[],
			},
			optionsCategories:[
				{
					value: 'sav',
					label: 'SAV'
				},
				{
					value: 'demos',
					label: 'Démos'
				},
				{
					value: 'receptionsTechniques',
					label: 'Réceptions techniques'
				},
				{
					value: 'miseEnService',
					label: 'Mise en service'
				},
				{
					value: 'formation',
					label: 'Formation'
				},
				{
					value: 'vae',
					label: 'VAE (Ventes Aux Enchères)'
				},
				{
					value: 'absence',
					label: 'Absence'
				},
				{
					value: 'conges',
					label: 'Congés'
				},
				{
					value: 'ferie',
					label: 'Fériés si pas automatiques'
				},
				{
					value: 'chargement',
					label: 'Chargement'
				},
				{
					value: 'dechargement',
					label: 'Déchargement'
				},
				{
					value: 'preparationMachine',
					label: 'Préparation des machines'
				},
				{
					value: 'formationPI',
					label: 'Formation du personnel interne'
				},
				{
					value: 'reglementAttente',
					label: 'Règlement en attente'
				},
				{
					value: 'enAttenteDeLivraison',
					label: 'En attente de livraison de pièces détachées'
				}
			],
			optionsEquipe:[
				{
					text: 'Équipe technique',
					value: 'Équipe technique'
				},
				{
					text: 'Équipe commerciale',
					value: 'Équipe commerciale'
				},
				{
					text: 'Équipe administrative',
					value: 'Équipe administrative'
				}
			],
			optionsSite:[
				{
					label:"Leers",
					value:"Leers"
				},
				{
					label:"Roissy",
					value:"Roissy"
				},
				{
					label:"Meyzieu",
					value:"Meyzieu"
				}
			],
		}
	},
	methods: {
		handleRemove() {
			// console.log(file, fileList);
		},
		handlePreview() {
			// console.log(file);
		},
		handleExceed(files, fileList) {
			this.$message.warning(`La limite est 1, vous avez choisi ${files.length} fichiers, soit ${files.length + fileList.length} au total.`);
		},
		beforeRemove(file) {
			return this.$confirm(`Supprimer le transfert de ${ file.name } ?`);
		},
		submitUpload() {
			this.$refs.upload.submit()
		},
		loadRessource: script.loadRessource,
		exportCal: script.exportCal,
		importCal: script.importCal,
		handleFileUpload(){
			this.file = this.$refs.file.files[0]
		},
		getEvents: script.getEvents,
	},
	components:{
	},
	mounted() {
		this.loadRessource()
		this.getEvents("datep>'2018-01-01T00:00:00'")
	},
	computed:{
		ressources(){
			return this.$store.state.ressources
		},
		filterNomClient(){
			let nomClient= []
			this.$store.state.events.forEach(event => {
				let eventFind = nomClient.filter(client => client.value == event.details.nomClient)
				if(!eventFind.length){
					nomClient.push({
						value:event.details.nomClient,
						label:event.details.nomClient
					})
				}
			})
			return nomClient
		},
		filterNumStock(){
			let numStock= []
			this.$store.state.events.forEach(event => {
				let eventFind = numStock.filter(stock => stock.value == event.details.numStock)
				if(!eventFind.length){
					numStock.push({
						value:event.details.numStock,
						label:event.details.numStock
					})
				}
			})
			return numStock
		},
		filterAnnee(){
			let now = moment()
			let anneeMax = now.add(20, 'y')
			let annee = []
			for(var i = 1990; i < anneeMax.year(); i++){
				annee.push({
					value: i,
					label: i
				})
			}
			return annee
		},
		filterSemaines(){
			let semaines = []
			for(var i = 1; i < 54; i++){
				semaines.push({
					value: i,
					label: "Semaine n° "+i
				})
			}
			return semaines
		},
		filterMois(){
			let semaines = []
			for(var i = 0; i < 12; i++){
				semaines.push({
					value: i,
					label: moment().month(i).format('MMMM')
				})
			}
			return semaines
		}
	}
}
</script>

<style>
.importCal{
	display: flex;
	flex-direction: column;
	padding: 1em;
	align-items: center;
}
#labelFile{
	padding: 1em;
}
#btnImport{
	width: 15%;
}
</style>