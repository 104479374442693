import conf from '../config/api.js';
import Vue from 'vue';
import vuexStorage from '../store/index.js'
// import { object } from 'underscore';

const categoriesColors = {
	sav : "#FF0000",
	demos: "#00E0FF",
	receptionsTechniques: "#00FF00",
	miseEnService: "#FFFF00",
	formation: "#FFFF00",
	vae: "#FF9EC7",
	absence: "#FF7000",
	conges: "#7D7D7D",
	ferie: "#7D7D7D",
	chargement: "#0000FF",
	dechargement: "#0000FF",
	preparationMachine: "#854000",
	formationPI: "#B200FF",
	rdvCom: "#005400",
	reunion: "#C8A41D",
	reglementAttente: "#FF1493",
	enAttenteDeLivraison: "#8A9917",
	default: "#7D7D7D"
}

var moment = require('moment');
moment.locale('fr')
//var htmlEncode = require('js-htmlencode').htmlEncode;
//var htmlDecode = require('js-htmlencode').htmlDecode;

//Fonction génération de toaster erreur
function toasterError(content, title){
	new Vue().$notify({
        title: title,
        message: content,
        type: 'error',
        position: 'bottom-right'
    })
}

//Fonction génération de toaster réussite
function toasterSuccess(content, title){
	new Vue().$notify({
        title: title,
        message: content,
        type: 'success',
        position: 'bottom-right'
    })
}

//Fonction récupération des agendas
async function getEvents(sqlFilters){
	try {
		let responseEvents = await requestApi({method: 'GET', url: '/api/getEvents' + '?nocache=' + new Date().getTime(), params: {sqlFilters: sqlFilters}, form: {}, headers: {'Content-Type': 'application/json'}})
		if(responseEvents != "OK"){
			responseEvents.forEach(async event => {
				let eventToAdd = {}
				var backgroundColor = "default"
				var borderColor = "default"
				if(Object.keys(event.array_options).length){
					let details = JSON.parse(event.array_options.options_details_evt)
					if (details != null){
						let categoriesLength = details.categories.length
						if(categoriesLength){
							if(categoriesLength>1){
								backgroundColor = details.categories[0]
								borderColor = details.categories[1]
							}else{
								backgroundColor = details.categories[0]
								borderColor = details.categories[0]
							}
						}

						var textColor = "white"
						if(categoriesColors[backgroundColor] == "#FFFF00"){
							textColor = "black"
						}

						if(event.fulldayevent == "1"){
							eventToAdd = {
								id: event.id,
								title: event.label,
								start: moment.unix(event.datep).format(),
								end: moment.unix(event.datef).format(),
								allDay: true,
								resourceId:event.userownerid,
								backgroundColor: categoriesColors[backgroundColor],
								borderColor: categoriesColors[borderColor],
								textColor:textColor,
								author:event.authorid,
								details: details
							}
						}else{
							eventToAdd = {
								id: event.id,
								title: event.label,
								start: moment.unix(event.datep).format(),
								end: moment.unix(event.datef).format(),
								allDay: false,
								resourceId:event.userownerid,
								backgroundColor: categoriesColors[backgroundColor],
								borderColor: categoriesColors[borderColor],
								textColor:textColor,
								author:event.authorid,
								details: details
							}
						}
					}else{
						if(event.fulldayevent == "1"){
							eventToAdd = {
								id: event.id,
								title: event.label,
								start: moment.unix(event.datep).format(),
								end: moment.unix(event.datef).format(),
								resourceId:event.userownerid,
								allDay: true,
								backgroundColor: categoriesColors[backgroundColor],
								borderColor: categoriesColors[borderColor],
								textColor:textColor,
								author:event.authorid,
								details:{
									nomCreateur:'',
									nomClient: '',
									personneAffecte: '',
									numStock: '',
									dateDebut: moment.unix(event.datep).format(),
									dateFin: moment.unix(event.datef).format(),
									heureDebut: moment.unix(event.datep).format(),
									heureFin: moment.unix(event.datef).format(),
									adresse: '',
									commentaire: '',
									categories: [],
									valide: false,
									allDay: true,
									file:[],
									filePath:[],
									resourceEditable: true
								}
							}
						}else{
							eventToAdd = {
								id: event.id,
								title: event.label,
								start: moment.unix(event.datep).format(),
								end: moment.unix(event.datef).format(),
								resourceId:event.userownerid,
								allDay: false,
								backgroundColor: categoriesColors[backgroundColor],
								borderColor: categoriesColors[borderColor],
								textColor:textColor,
								author:event.authorid,
								details:{
									nomCreateur:'',
									nomClient: '',
									personneAffecte: '',
									numStock: '',
									dateDebut: moment.unix(event.datep).format(),
									dateFin: moment.unix(event.datef).format(),
									heureDebut: moment.unix(event.datep).format(),
									heureFin: moment.unix(event.datef).format(),
									adresse: '',
									commentaire: '',
									categories: [],
									valide: false,
									allDay: false,
									file:[],
									filePath:[],
									resourceEditable: true
								}
							}
						}
					}
				} else {
					eventToAdd = {
						id: event.id,
						title: event.label,
						start: moment.unix(event.datep).format(),
						end: moment.unix(event.datef).format(),
						resourceId:event.userownerid,
						allDay: false,
						backgroundColor: categoriesColors["default"],
						borderColor: categoriesColors["default"],
						textColor:textColor,
						author:event.authorid,
						details:{
							nomCreateur:'',
							nomClient: '',
							personneAffecte: '',
							numStock: '',
							dateDebut: moment.unix(event.datep).format(),
							dateFin: moment.unix(event.datef).format(),
							heureDebut: moment.unix(event.datep).format(),
							heureFin: moment.unix(event.datef).format(),
							adresse: '',
							commentaire: '',
							categories: [],
							valide: false,
							allDay: false,
							file:[],
							filePath:[],
							resourceEditable: true
						}
					}
				}
				vuexStorage.commit("addEvents",eventToAdd)
			})
		}
	} catch (error) {
		if(error.response != undefined){
			if(error.response.status == 401 || error.response.status == 400){
				this.$router.push('/');
			}
			toasterError(`Impossible de charger les évènements`,'Chargement des évènements')
		}else{
			toasterError(`Le service est momentanément indisponible`,'Service indisponible')
		}
	}
}

//Fonction d'ajout d'evenement sur l'agenda
async function putEvent(event, source,email,nomModif){
	let payload = {
		userownerid: event.resourceId,
		label: event.title,
		datef: moment(event.end).unix(),
		datep: moment(event.start).unix(),
		fulldayevent: event.allDay,
		array_options: {
			"details_evt": JSON.stringify(event.details)
		}
	}
	try {
		let postResponse = await requestApi({method: 'POST', url: '/api/postEvent' + '?nocache=' + new Date().getTime(), params: {}, form: payload, headers: {'Content-Type': 'application/json'}})
		try{
			event.id = postResponse.idEvent
			if(event.details.file){
				let files = event.details.file

				var formData = new FormData();
			
				for(var i=0; i < files.length; i++){
					formData.append('files', files[i]);
				}

				let filesPath = await requestApi({method: 'POST', url: '/api/uploadFiles', params: {}, form: formData, headers: {"content-type" : "multipart/form-data"}})
				
				filesPath.forEach(file => {
					event.details.filePath.push({
						name: file.filename,
						path: file.path,
						type: file.mimetype
					})
				})

				payload = {
					userownerid: event.resourceId,
					label: event.title,
					datef: moment(event.end).unix(),
					datep: moment(event.start).unix(),
					fulldayevent: event.allDay,
					array_options: {
						"details_evt": JSON.stringify(event.details)
					}
				}
			}
			if(source != "import" && event.details.valide){
				let message = "Validation de l'évènement : "+event.title+" par "+nomModif+"<br/>Nom du client : "+event.details.nomClient+"<br/>Numéro de stock : "+event.details.numStock+"<br/>Adresse : "+event.details.adresse+"<br/>Commentaire : "+event.details.commentaire+"<br/>"
				if(event.details.allDay){
					message += "Journée entière : Oui<br/>Date de début : " + moment(event.start).format('LLLL') + "<br/>Date de fin : " + moment(event.end).format('LLLL') + "<br/>"
				}else{
					message += "Journée entière : Non<br/>Date de début : " + moment(event.start).format('LLLL') + "<br/>Date de fin : " + moment(event.end).format('LLLL') + "<br/>"
				}
				if(event.details.categories.length){
					message += "Catégories : <br/>"
					event.details.categories.forEach(categorie => {
						message += categorie+"<br/>"
					})
				}else{
					message += "Catégories : <br/>Sans catégorie"
				}
				await requestApi({method: 'POST', url: '/api/sendMail?nocache=' + new Date().getTime(), params: {}, form: {to: email, message: message}, headers: {'Content-Type': 'application/json'}})
			}
			vuexStorage.dispatch("addEvent",event).then(async () => {
				await requestApi({method: 'POST', url: '/api/updateEvent/' + postResponse.idEvent + '?nocache=' + new Date().getTime(), params: {}, form: payload, headers: {'Content-Type': 'application/json'}})
				if(source != "import"){
					toasterSuccess(`Evènement créé avec succès`,'Création d\'évènement')
				}
			})
		} catch (error) {
			if(error.response != undefined){
				if(error.response.status == 401 || error.response.status == 400){
					this.$router.push('/');
				}
				if(source != "import"){
					toasterError(`Impossible de créer l'évènement`,'Création d\'évènement')
				}
			}else{
				toasterError(`Le service est momentanément indisponible`,'Service indisponible')
			}
		}
	} catch (error) {
		if(error.response != undefined){
			if(error.response.status == 401 || error.response.status == 400){
				this.$router.push('/');
			}
			if(source != "import"){
				toasterError(`Impossible de créer l'évènement`,'Création d\'évènement')
			}
		}else{
			toasterError(`Le service est momentanément indisponible`,'Service indisponible')
		}
	}
}

//Fonction suppression d'évènements
async function deleteEvent(id,event,email,nomModif){
	try {
		await requestApi({method: 'POST', url: '/api/deleteEvent/' + id + '?nocache=' + new Date().getTime(), params: {}, form: {}, headers: {'Content-Type': 'application/json'}})
		this.$store.commit("deleteEvent", id)
		if(event.details.valide){
			let message = "Suppression d'évènement : "+event.title+" par "+nomModif+"<br/>Nom du client : "+event.details.nomClient+"<br/>Numéro de stock : "+event.details.numStock+"<br/>"
			if(event.details.allDay){
				message += "Journée entière : Oui<br/>Date de début : " + moment(event.start).format('LLLL') + "<br/>Date de fin : " + moment(event.end).format('LLLL') + "<br/>"
			}else{
				message += "Journée entière : Non<br/>Date de début : " + moment(event.start).format('LLLL') + "<br/>Date de fin : " + moment(event.end).format('LLLL') + "<br/>"
			}
			await requestApi({method: 'POST', url: '/api/sendMail?nocache=' + new Date().getTime(), params: {}, form: {to: email, message: message}, headers: {'Content-Type': 'application/json'}})
		}	
		toasterSuccess(`Suppression d'évènement réussie`,`Suppression d'évènement`)
	} catch (error) {
		if(error.response != undefined){
			if(error.response.status == 401 || error.response.status == 400){
				this.$router.push('/');
			}
			toasterError(`Impossible de supprimer l'évènement`,`Suppression d'évènement`)
		}else{
			toasterError(`Le service est momentanément indisponible`,'Service indisponible')
		}
	}
}

//Fonction de mise à jours d'event
async function updateEvent(event,email,nomModif){
	let payload = {
		userownerid: event.resourceId,
		label: event.title,
		datef: moment(event.end).unix(),
		datep: moment(event.start).unix(),
		fulldayevent: event.allDay,
		array_options: {
			"details_evt": JSON.stringify(event.details)
		}
	}
	try {
		await requestApi({method: 'POST', url: '/api/updateEvent/' + event.id + '?nocache=' + new Date().getTime(), params: {}, form: payload, headers: {'Content-Type': 'application/json'}})
		if(event.details.valide){
			let message = "Validation de l'évènement : "+event.title+" par "+nomModif+"<br/>Nom du client : "+event.details.nomClient+"<br/>Numéro de stock : "+event.details.numStock+"<br/>Adresse : "+event.details.adresse+"<br/>Commentaire : "+event.details.commentaire+"<br/>"
			if(event.details.allDay){
				message += "Journée entière : Oui<br/>Date de début : " + moment(event.start).format('LLLL') + "<br/>Date de fin : " + moment(event.end).format('LLLL') + "<br/>"
			}else{
				message += "Journée entière : Non<br/>Date de début : " + moment(event.start).format('LLLL') + "<br/>Date de fin : " + moment(event.end).format('LLLL') + "<br/>"
			}
			if(event.details.categories.length){
				message += "Catégories : <br/>"
				event.details.categories.forEach(categorie => {
					message += categorie+"<br/>"
				})
			}else{
				message += "Catégories : <br/>Sans catégorie"
			}
			await requestApi({method: 'POST', url: '/api/sendMail?nocache=' + new Date().getTime(), params: {}, form: {to: email, message: message}, headers: {'Content-Type': 'application/json'}})
		}
			toasterSuccess(`Mise à jour réussie`,`Mise à jour d'évènement`)
	} catch (error) {
		if(error.response != undefined){
			if(error.response.status == 401 || error.response.status == 400){
				this.$router.push('/');
			}
			toasterError(`Impossible de modifier l'évènement`,`Mise à jour d'évènement`)
		}else{
			toasterError(`Le service est momentanément indisponible`,'Service indisponible')
		}
	}
}

//Fonction chargement des ressources
async function loadRessource(){
	try {
		let response = await requestApi({method: 'GET', url: '/api/getRessource' + '?nocache=' + new Date().getTime(), params: {}, form: {}, headers: {'Content-Type': 'application/json'}})
		let ressources = []
		response.forEach(element => {
			ressources.push({
				title: element.firstname + " " + element.lastname,
				id: element.id,
				groupId: element.groupe.name,
				memberDetail: element
			})
		})
		vuexStorage.commit('initializeRessources',ressources)
	} catch (error) {
		if(error.response != undefined){
			if(error.response.status == 401 || error.response.status == 400){
				this.$router.push('/');
			}
			toasterError(`Impossible de charger les ressources`,'Chargement des ressources')
		}else{
			toasterError(`Le service est momentanément indisponible`,'Service indisponible')
		}
	}
}

//Fonction de chargement des groupes
async function loadGroups(){
	try {
		let response = await requestApi({method: 'GET', url: '/api/getGroups' + '?nocache=' + new Date().getTime(), params: {}, form: {}, headers: {'Content-Type': 'application/json'}})
		var groupes = []
		response.forEach(element => {
			groupes.push({value: element.id, label: element.nom})
		})
		this.optionsGroupe = groupes
	} catch (error) {
		if(error.response != undefined){
			if(error.response.status == 401 || error.response.status == 400){
				this.$router.push('/');
			}
			toasterError(`Impossible de charger les groupes`,'Chargement des groupes')
		}else{
			toasterError(`Le service est momentanément indisponible`,'Service indisponible')
		}
	}
}

//Fonction de création de ressource
async function createRessource(payload){
	try {
		await requestApi({method: 'POST', url: '/api/postRessource' + '?nocache=' + new Date().getTime(), params: {}, form: payload, headers: {'Content-Type': 'application/json'}})
		loadRessource()
		toasterSuccess(`Création de la ressource réussie`,`Création de Ressource`)
	} catch (error) {
		if(error.response != undefined){
			if(error.response.status == 401 || error.response.status == 400){
				this.$router.push('/');
			}
			toasterError(`Impossible de créer la ressource`,'Création de ressource')
		}else{
			toasterError(`Le service est momentanément indisponible`,'Service indisponible')
		}
	}
}

//Fonction de suppression de ressource
async function deleteRessource(id){
	try {
		await requestApi({method: 'POST', url: '/api/deleteRessource/' + id + '?nocache=' + new Date().getTime(), params: {}, form: {}, headers: {'Content-Type': 'application/json'}})
		vuexStorage.commit('deleteRessource', id)
		toasterSuccess(`Suppression de la ressource réussie`,`Suppression de Ressource`)
	} catch (error) {
		if(error.response != undefined){
			if(error.response.status == 401 || error.response.status == 400){
				this.$router.push('/');
			}
			toasterError(`Impossible de supprimer la ressource`,`Suppression de Ressource`)
		}else{
			toasterError(`Le service est momentanément indisponible`,'Service indisponible')
		}
		
	}
}

//Fonction d'export de calendrier excel sous csv
async function exportCal(form){
	let now = moment()
	let payload = {
		form: form,
		name: 'Export calendrier - '+ now.format('L')+'.xlsx',
		events: this.$store.state.events,
		ressources: this.$store.state.ressources,
	}
	this.axios.post(conf.server+"/api/exportCal",payload,{withCredentials:true, responseType:'blob'})
	.then((response) => {
		var link = document.createElement('a');
        link.download = payload.name;
        link.href = URL.createObjectURL(response.data);
        document.documentElement.appendChild(link).click();
        document.documentElement.removeChild(link);
	})
	.catch((error) => {
		console.dir(error)
		if(error.response != undefined){
			if(error.response.status == 401 || error.response.status == 400){
				this.$router.push('/');
			}
			toasterError(`Impossible de créer l'export CSV`,'Export de calendrier')
		}else{
			toasterError(`Le service est momentanément indisponible`,'Service indisponible')
		}
	})
}

//Upload de fichier
async function uploadfile(event, file){
	try{
		if(file){
			let formData = new FormData()

			formData.append('file', file)

			let filesPath = await requestApi({method: 'POST', url: '/api/uploadFile', params: {}, form: formData, headers: {"content-type" : "multipart/form-data"}})
			
			
			event.details.filePath.push({
				name: filesPath.filename,
				path: filesPath.path,
				type: filesPath.mimetype
			})

			let payload = {
				userownerid: event.resourceId,
				label: event.title,
				datef: moment(event.end).unix(),
				datep: moment(event.start).unix(),
				fulldayevent: event.allDay,
				array_options: {
					"details_evt": JSON.stringify(event.details)
				}
			}
			
			await requestApi({method: 'POST', url: '/api/updateEvent/' + event.id + '?nocache=' + new Date().getTime(), params: {}, form: payload, headers: {'Content-Type': 'application/json'}})
			toasterSuccess(`Ajout de fichier réussi`,'Ajout de fichier')
		} else{
			toasterError(`Veuillez ajouter un fichier à joindre`,'Ajout de fichier')
		}
	} catch (error) {
		if(error.response != undefined){
			if(error.response.status == 401 || error.response.status == 400){
				this.$router.push('/');
			}
			toasterError(`Impossible d'ajouter le fichier`,'Ajout de fichier')
		}else{
			toasterError(`Le service est momentanément indisponible`,'Service indisponible')
		}
	}
}

//Import calendrier
async function importCal(){
	let formData = new FormData()

	formData.append('calendar', this.file)

	this.axios.post(conf.server+"/api/importCal",formData,{headers:{"content-type" : "multipart/form-data"},withCredentials:true})
	.then((response) => {
		let events = response.data
		events.forEach(element => {
			putEvent(element, "import")
		})
		toasterSuccess(`Importation du calendrier réussi`,`Importation de calendrier`)
	})
	.catch((error) => {
		if(error.response != undefined){
			if(error.response.status == 401 || error.response.status == 400){
				this.$router.push('/');
			}
			toasterError(`Importation du calendrier échoué`,`Importation de calendrier`)
		}else{
			toasterError(`Le service est momentanément indisponible`,'Service indisponible')
		}
	})
}

// //Fonction création xlsx
// function createXlsx() {
// 	let payload = {
// 		infosGenerales : vuexStorage.state.infosGenerales,
// 		cotation : vuexStorage.state.cotation,
// 		famillesRisques : vuexStorage.state.famillesRisques,
// 		unites : vuexStorage.state.unites,
// 		donnees : vuexStorage.state.donnees,
// 		situations : vuexStorage.state.situations,
// 		infoSoc : vuexStorage.state.infoSoc,
// 		dateMaj : parseInt(vuexStorage.state.dateMaj,10),
// 		dateCreation : parseInt(vuexStorage.state.dateCreation,10)
// 	}

// 	this.axios.post(conf.server+"/api/getXlsx",payload,{withCredentials:true, responseType:'blob'})
// 	.then((response) => {
// 		var link = document.createElement('a');
//         link.download = 'DUERP '+ payload.infoSoc.name+'.xlsx';
//         link.href = URL.createObjectURL(response.data);
//         document.documentElement.appendChild(link).click();
//         document.documentElement.removeChild(link);
// 	})
// 	.catch((error) => {
// 		console.dir(error)
// 		if(error.response != undefined){
// 			toasterError(`Impossible de charger les informations`,'Chargement des paramètres par défaut')
// 		}else{
// 			toasterError(`Le service est momentanément indisponible`,'Service indisponible')
// 		}
// 	})
// }

// Fonction appel api
/*
  args: options : method -- required
                  url -- required
                  params -- optional -- url parameters
                  form -- optional -- request body applicable only with PUT, POST and Patch methods
                  headers -- optional
*/
function requestApi (options) {
	return new Promise((resolve, reject) => {
		new Vue().axios({
			method: options.method,
			url: conf.server + options.url,
			params: options.params,
			data: options.form,
			headers: options.headers,
			withCredentials: true
		}).then((response) => {
			resolve(response.data)
		}).catch((error) => {
			reject(error)
		})
	})
}

export default {
	getEvents,
	toasterError,
	toasterSuccess,
	putEvent,
	deleteEvent,
	loadGroups,
	createRessource,
	loadRessource,
	exportCal,
	deleteRessource,
	uploadfile,
	importCal,
	updateEvent,
}
