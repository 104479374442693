<template>
  <div>
    <b-form id="changePass" @submit.prevent='onSubmit'>
      <label>Changement de mot de passe</label>
      <p>Veuillez saisir votre mot de passe actuel ainsi que deux fois votre nouveau mot de passe. Celui-ci doit contenir au minimum une majuscule, un chiffre et avoir une longueur de 8 caractères.</p>
      <b-form-group id="password" label="Mot de passe actuel :*" label-for="inputPassword">
        <b-form-input type="password" id="inputPassword" v-model="post.pwd"></b-form-input>
      </b-form-group>
      <b-form-group id="newPassword" label="Nouveau mot de passe :*" label-for="inputNewPassword">
        <b-form-input type="password" id="inputNewPassword" v-model="post.newPwd"></b-form-input>
      </b-form-group>
      <b-form-group id="newPassword2" label="Resaisir mot de passe :*" label-for="inputNewPassword2">
        <b-form-input type="password" id="inputNewPassword2" v-model="post.newPwd2"></b-form-input>
      </b-form-group>

      <b-button type="submit" variant="primary">Envoyer</b-button>
      <p>Les champs indiqués par une '*' sont obligatoires.</p>
    </b-form> 
  </div>
</template>

<style>
#changePass{
  width: 50%;
  margin: auto;
  margin-top: 3em;
}

#password, #newPassword, #newPassword2{
  width: 50%;
}
</style>

<script>
import script from '../../script/script.js';

export default {
  data() {
    return {
      post: {},
    }
  },
  mounted:function(){
    this.verifyRight();
    this.activeClassMenuProfil();
  },
  methods: {
    verifyRight: script.verifyRight,
    changePassword: script.changePassword,
    onSubmit(){
      this.changePassword(this.post);
    },
    activeClassMenuProfil: script.activeClassMenuProfil
  },
  components: {
    
  }
}
</script>
