<template>
  <div>
    <h1>Forbidden</h1>
    <router-link :to="'/'">Revenir à l'accueil</router-link>
  </div>  
</template>
<script>
export default {
  
}
</script>
<style>

</style>
