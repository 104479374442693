<template>
  <div>
    <div class="body bodylogin">
      <div class="login_center">
        <!-- Formulaire de connexion -->
        <form class="form-horizontal" v-on:submit.prevent="setPass" method="post" >
          <div class="login_table">
            <div class="login-logo">
              <img src="/img/logo-white.png" alt="FMO" />
            </div>
            <br />
            <div class="login-fields">
              <div id="login-status">
              </div>
              <label style="color:white;">Saisir mot de passe :</label>
              <div class="input-group">
                <span class="input-group-addon"><i class="fas fa-user"></i></span>
                <!-- Ajout d'un champ pour le login -->
                <input type="password" class="form-control" id="pwd" placeholder="Mot de passe" v-model="post.pwd" required/>
              </div>
              <br/>
              <label style="color:white;">Resaisir mot de passe :</label>
              <div class="input-group">
                <span class="input-group-addon"><i class="fas fa-user"></i></span>
                  <input type="password" class="form-control" id="pwd2" placeholder="Mot de passe" v-model="post.pwd2" required/>
              </div>
              <!-- Button Connection -->
              <br />
              <input type="submit" class="btn btn-primary btn-block" value="Créer mot de passe" tabindex="5" />
            </div>
          </div> <!-- end login table -->
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import script from '../script/script.js';

export default {
  data() {
    return {
      post: {}
    }
  },
  mounted:function(){
    this.verifyToken();
  },
  created:function(){
    
  },
  methods: {
    setPass: script.setPass,
    verifyToken: script.verifyToken
  }
}
</script>

<style>

</style>
