<template>
    <div id="help">
        <el-tabs tab-position="left" style="">
            <el-tab-pane label="Connexion à l’outil">
                <div class="tabHelp">
                    <p>Pré-requis :</p>
                    <ul>
                        <li>Identifiants de connexion</li>
                    </ul>
                    <p>Pour vous connectez au planning munissez vous de vos identifiants de connexion et rendez-vous sur la page de <a href="https://fmo-planning.ideal-dev.fr/login">login</a>.</p>
                    <p>Une fois sur cette page renseigner votre login et mot de passe et cliquer sur connexion.</p>
                    <img src="img/help/planning/connexion.png" alt="connexion" />
                    <p>Une fois connecté vous arriverez sur la page d’accueil de l’outil de planning.</p>
                </div>
            </el-tab-pane>
            <el-tab-pane label="Menu">
                <div class="tabHelp">
                    <p>Le menu se compose de quatre entrées qui sont :</p>
                    <ul>
                        <li>L’accueil</li>
                        <li>Le planning</li>
                        <li>La configuration du planning</li>
                        <li>L'aide'</li>
                    </ul>
                    <div>
                        <img src="img/help/planning/menu-plie.png" alt="menu-plié" style="padding-right:10em;"/>
                        <img src="img/help/planning/menu-deplie.png" alt="menu-déplié" />
                    </div>
                    <p>Par défaut le menu est « plié » vous pouvez le « déplier » en cliquant sur l’icône de double flèches qui se situe en bas du menu.</p>
                    <img src="img/help/planning/bouton-plie.png" alt="bouton-plié" />
                </div>
            </el-tab-pane>
            <el-tab-pane label="Planning">
                <div class="tabHelp">
                    <ul>
                        <li><h5>Affichage</h5></li>
                        <p>Dans l’affichage du planning nous retrouvons sur la gauche les ressources du planning triées par équipe (Technique, Commerciale, Administrative). </p>
                        <img src="img/help/planning/affichage-ressource.png" alt="affichage-ressource" />
                        <p>Sur la droite nous retrouverons les évènements correspondant à chaque ressources avec un code couleurs différents suivant le type d’évènement (une légende se situe en dessous du planning).</p>
                        <img src="img/help/planning/categories.png" alt="categories" />
                        <img src="img/help/planning/affichage-planning.png" alt="affichage-planning" />
                        <p>Vous pouvez naviguer dans le planning grâce aux flèches de navigation.</p>
                        <img src="img/help/planning/changer-jour.png" alt="changer-jour" />
                        <p>Vous pouvez changer l’affichage du planning, soit un affichage au jour, à la semaine, au mois ou à l’année grâce aux boutons en haut à droite du planning.</p>
                        <img src="img/help/planning/changer-periode.png" alt="hanger-periode" />
                        <li><h5>Création d’évènements</h5></li>
                        <p>Pré-requis :</p>
                        <ul>
                            <li>Avoir les droits de création d’évènement</li>
                        </ul>
                        <p>Pour créer un évènement il vous suffit de cliquer sur le planning. La date de début de l’évènement et la ressource sont pré-sélectionner sur le jour et la ligne ou vous avez cliqué. Il vous reste ensuite à remplir le formulaire et à valider la création de l’évènement. Jusqu’à trois ressources peuvent être sélectionner ainsi que deux catégories. Attention à ce que la date de début ne soit pas supérieure à la date de fin. Des fichiers peuvent être ajoutés à l’évènement, vous pourrez les retrouver en téléchargement lors de la consultation de l’évènement.</p>
                        <img src="img/help/planning/ajout-event.png" alt="ajout-event" />
                        <li><h5>Modification d’évènements</h5></li>
                        <p>Pré-requis :</p>
                        <ul>
                            <li>Être le créateur de l’évenement ou être administrateur</li>
                            <li>L’évènement ne soit pas validé</li>
                        </ul>
                        <p>Pour modifier un évènement deux possibilités s’offre à vous :</p>
                        <ul>
                            <li>Cliquer sur l’évènement et remplir le formulaire de modification</li>
                            <li>Glisser / Déposer l’évènement directement sur le planning</li>
                        </ul>
                        <li><h5>Recherche</h5></li>
                        <p>Vous pouvez effectuer une recherche par n’importe quel mot clés. Cette recherche sera faite sur le nom de la ressource, le nom du client ou le numéro de stock. Pour faire une recherche rentrer un mot clés dans le champ de recherche et cliquer sur l’icône en forme de loupe.</p>
                        <img src="img/help/planning/rechercher.png" alt="rechercher" />
                        <p>Une fois la recherche effectuée les résultats s’afficheront dans une fenêtre pop-up et vous pourrez ajouter des filtres supplémentaires à la recherche.</p>
                        <li><h5>Filtres</h5></li>
                        <p>Vous pouvez choisir des filtres sur le planning afin de n’avoir que certains évènements ou certaines ressources.</p>
                        <img src="img/help/planning/filtrer.png" alt="filtrer" />
                    </ul>
                </div>
            </el-tab-pane>
            <el-tab-pane label="Configuration du planning">
                <div class="tabHelp">
                    <ul>
                        <li><h5>Ressources</h5></li>
                        <p>Administrateur :</p>
                        <p>Vous pouvez ajouter des ressources depuis le formulaire d’ajout de ressource. Il vous faut remplir le formulaire et cliquer sur « Ajouter la ressource ».</p>
                        <img src="img/help/planning/ajout-ressource.png" alt="ajout-ressource" />
                        <p>Pour supprimer une ressource aller sur la liste des ressources et cliquez sur le bouton supprimer, sur la ligne de la ressource que vous souhaitez supprimer.</p>
                        <img src="img/help/planning/supprimer-ressource.png" alt="supprimer-ressource" />
                        <p>Utilisateur :</p>
                        <p>Vous pouvez consulter la liste des ressources.</p>
                        <li><h5>Import / Export</h5></li>
                        <p>Vous pouvez importer n’importe quel type de calendrier au format .ics, charger les fichier et cliquez sur « Importer le calendrier »</p>
                        <img src="img/help/planning/importer-calendrier.png" alt="importer-calendrier" />
                        <p>Pour exporter un calendrier, aller sur l’onglet exporter, choisisser vos préférences et cliquer sur « Exporter sous .csv »</p>
                        <img src="img/help/planning/exporter-calendrier.png" alt="exporter-calendrier" />
                    </ul>
                </div>
            </el-tab-pane>
            <el-tab-pane label="Support technique développement">
                <div class="tabHelp">
                    <p>En cas de problème veuillez contacter le support technique Idéal Solutions :</p>
                    <a href="mailto:support@ideal-solutions.fr">support@ideal-solutions.fr</a>
                    <br/>
                    <a href="tel:0971180618">09 71 18 06 18</a>
                </div>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>

<script>
export default {
  data () {
    return {
    }
  },
  watch: {
  },
  props: {
  },
  methods: {
  },

}
</script>

<style>
#help img{
    max-width: 100%;
}

#help .tabHelp{
    padding: 2em;
}
</style>