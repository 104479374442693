import Vuex from 'vuex'

import Vue from 'vue'

Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		render: 0,
		info: {
			id:"",
			type:"",
			nom:"",
			prenom:"",
			addEventTech:"",
		},
		events: [],
		currentEvent:{},
		ressources:[]
	},
	mutations: {
		rendering (state) {
			state.render += 1
		},
		updateInfo(state, info) {
			state.info = info
		},
		setEvents(state, data){
			state.events = data
		},
		addEvents(state, event){
			let events = state.events.filter(element => element.id == event.id)
			if(!events.length){
				state.events.push(event)
			}
		},
		updateEvent(state, event){
			let events = state.events.filter(_event => _event.id == event.id)

			events[0] = event
			events[0].title = event.title
			events[0].allDay = event.details.allDay
			events[0].backgroundColor = event.backgroundColor
			events[0].borderColor = event.borderColor
			events[0].start = event.start
			events[0].end = event.end
			events[0].resourceId = event.resourceId
			
		},
		updateEventResizeDrop(state, info){
			let eventFind = state.events.filter(event => event.id == info.event.id)

			eventFind[0].end = info.event.end
			eventFind[0].start = info.event.start
			eventFind[0].details.dateFin = info.event.end
			eventFind[0].details.dateDebut = info.event.start
			eventFind[0].details.heureFin = info.event.end
			eventFind[0].details.heureDebut = info.event.start
			if(info.newResource){
				for(var i = 0; i < eventFind[0].details.personneAffecte.length; i++){
					if(eventFind[0].details.personneAffecte[i] == info.oldResource.id){
						eventFind[0].details.personneAffecte[i] = info.newResource.id
					}
				}
				eventFind[0].resourceId = info.newResource.id
			}
			
		},
		deleteEvent(state, id){
			state.events = state.events.filter(event => event.id != id)
		},
		getEvent(state, {id}){
			let event = state.events.filter(event => event.id == id)
			state.currentEvent = event[0]
		},
		initializeRessources(state, data){
			state.ressources = data
		},
		deleteRessource(state, id){
			state.ressources = state.ressources.filter(ressource => ressource.memberDetail.id != id)
		},
		setUserInfo(state, data){
			state.info.id = data.id
			state.info.type = data.type
			state.info.nom = data.nom
			state.info.prenom = data.prenom
			state.info.addEventTech = data.addEventTech
		},
		deleteUserInfo(state){
			state.info = {}
		}
	},
	actions:{
		addEvent(context, event){
			context.commit('addEvents', event)
		}
	}
})