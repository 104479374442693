import Vue from 'vue';
import Vuex from 'vuex';
import App from './App.vue';
import BootstrapVue from 'bootstrap-vue';
import CKEditor from '@ckeditor/ckeditor5-vue';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import "vue-wysiwyg/dist/vueWysiwyg.css";
import VueRouter from 'vue-router';
import VueAxios from 'vue-axios';
import axios from 'axios';
import VueSidebarMenu from 'vue-sidebar-menu';
import wysiwyg from "vue-wysiwyg";
import VueBootstrapTypeahead from './components/typeahead/VueBootstrapTypeahead.vue'
import VuePapeParse from 'vue-papa-parse';
import { VueSpinners } from '@saeris/vue-spinners'
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';
import underscore from 'vue-underscore';
import VueBootstrap4Table from 'vue-bootstrap4-table'
import VueMobileDetection from "vue-mobile-detection";
import InputTag from 'vue-input-tag'
import VCalendar from 'v-calendar';
import Element from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import locale from 'element-ui/lib/locale/lang/fr'
import modal from 'vue-js-modal'

import store from './store/index'

Vue.use(Vuex);
Vue.use(Element, {locale});
Vue.use(VueSidebarMenu);
Vue.use(BootstrapVue);
Vue.use(VueRouter);
Vue.use(VueAxios, axios);
Vue.use(wysiwyg, {hideModules: { "image": true, "unorderedList": true,"orderedList": true,"code": true,"link": true,"headings": true,"table": true,"removeFormat": true,"separator": true}});
Vue.use(CKEditor);
Vue.use(VuePapeParse);
Vue.use(VueSpinners);
Vue.component('vue-phone-number-input', VuePhoneNumberInput);
Vue.use(underscore);
Vue.component('vue-bootstrap4-table', VueBootstrap4Table);
Vue.use(VueMobileDetection);
Vue.component('input-tag', InputTag)
Vue.use(VCalendar);
Vue.use(require('vue-moment'));
Vue.use(modal, {dialog:true, dynamic:true})



Vue.config.productionTip = false;
Vue.config.performance = true;


import Login from './components/Login.vue';
import Index from './components/Index.vue';
import ChangePassOublie from './components/ChangePassOublie.vue';
import PremiereConnexion from './components/PremiereConnexion.vue';
import ChangePass from './components/routes/ChangePass.vue';

import IndexAdmin from './components/routes/IndexAdmin.vue';
import ContenuAdmin from './components/routes/ContenuAdmin.vue';
import Planning from './components/modules/modulePlanning/Planning.vue';
import ConfigurationPlanning from './components/modules/modulePlanning/ConfigurationPlanning.vue';
import Aide from './components/modules/modulePlanning/Aide.vue';
 
import Error403 from './components/403.vue';
import Error404 from './components/404.vue';

//Vue admin
Vue.component('index-admin-layout', IndexAdmin);
Vue.component('change-pass-client-layout', ChangePass);
Vue.component('planning-layout', Planning);
Vue.component('configuration-planning-layout', ConfigurationPlanning);
Vue.component('aide-layout', Aide);

//Composants perso.
Vue.component('vue-bootstrap-typeahead', VueBootstrapTypeahead)


const routes = [
    {
        name: 'home',
        path: '/',
        component: Index
    },
    {
        name: 'login',
        path: '/login',
        component: Login
    },
    {
        name: 'changePassOublie',
        path: '/changePassOublie/:token',
        component: ChangePassOublie
    },
    {
        name: 'premiereConnexion',
        path: '/premiereConnexion/:token',
        component: PremiereConnexion
    },
    {
        name: 'indexAdmin',
        path: '/indexAdmin',
        component: ContenuAdmin,
        meta: {
            layout: "index-admin"
        }
	},
	{
        name: 'planning',
        path: '/planning',
        component: ContenuAdmin,
        meta: {
            layout: "planning"
        }
	},
	{
        name: 'configurationPlanning',
        path: '/configurationPlanning',
        component: ContenuAdmin,
        meta: {
            layout: "configuration-planning"
        }
    },
	{
        name: 'aide',
        path: '/aide',
        component: ContenuAdmin,
        meta: {
            layout: "aide"
        }
    },
    {
        name: 'error403',
        path: '/error403',
        component: Error403
    },
    {
        path: '*',
        component: Error404
    }
];

const router = new VueRouter({ mode: 'history', routes: routes});

// new Vue(Vue.util.extend({ router }, App)).$mount('#app');
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')