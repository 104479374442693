<template>
  <div>
    <div class="body bodylogin">
      <div class="login_center">
        <!-- Formulaire de connexion -->
        <form class="form-horizontal" v-on:submit.prevent="connect()" method="post" >
          <div class="login_table">
            <div class="login-logo">
              <img src="img/logo-fmo.png" alt="FMO" />
            </div>
            <br/>
            <div class="login-fields">
              <div id="login-status">
              </div>
              <div class="input-group">
                <span class="input-group-addon"><i class="fas fa-user"></i></span>
                <!-- Ajout d'un champ pour le login -->
                <input type="text" class="form-control" id="email" placeholder="Login" v-model="post.email" required/>
              </div>
              <div class="input-group">
                <span class="input-group-addon"><i class="fas fa-lock"></i></span>
                  <input type="password" class="form-control" id="pwd" placeholder="Mot de passe" v-model="post.pwd" required/>
              </div>

              <!-- Button Connection -->
              <br/>
              <input type="submit" class="btn btn-primary btn-block" value="Connexion" tabindex="5" />
              <!-- <div class="mt-3">
                <a v-on:click.prevent="openModal($event.target)" data-toggle="modal" href="#modalMdpOublie">Mot de passe oublié ?</a>
              </div> -->
            </div>
          </div> <!-- end login table -->
        </form>
      </div>
    </div>
    <!-- Modal d'oublie de mot de passe-->
    <!-- <b-modal id="modalOublieMDP" @hide="resetModal" :title="modalOublieMDP.title" hide-footer>
      <b-form>
        <b-form-group id="input-group-login" label="Veuillez entrer votre login :" label-for="input-login">
          <b-form-input type="email" id="input-login" v-model="loginOublie"></b-form-input>
        </b-form-group>
        <b-button id="sendChangePass" block v-on:click="changePassOublie" variant="primary">Envoyer</b-button>
      </b-form>
    </b-modal> -->
  </div>
</template>

<script>
import conf from '../config/api.js';
import script from '../script/script.js';

export default {
    data() {
        return {
            post: {},
            modalOublieMDP: { title: "Formulaire d'oubli de mot de passe", content: '' },
            loginOublie:""
        }
    },
    created:function(){
      this.isConnect();
    },
    methods: {
        toasterError: script.toasterError,
        connect() {
            this.axios.post(conf.server+"/api/connect", this.post,{withCredentials:true})
            .then((response) => {
                if(response.status==200) {
                    if (this.$route.query.redirect) {
                        this.$router.push(this.$route.query.redirect)
                    }
                    else {
						this.$router.push(response.data.route);
                    }
                }
                else{
                    this.$router.push('login');
                }
            })
            .catch(() => {
                this.toasterError(`Identifiant ou mot de passe incorrect`,'Identification')
            })
        },
        isConnect: script.isConnect,
        openModal(target){
          this.$root.$emit('bv::show::modal', 'modalOublieMDP',target);
        },
        changePassOublie: script.changePassOublie
    }
}
</script>

<style scoped>
    #createAccount {
      background-color: #F8931C;
      border-color: #FF8707;
    }
    #createAccount:hover {
      background-color: #F8641C;
    }
</style>


