<template>
	<div>
		<el-tabs type="border-card">
			<el-tab-pane label="Ressources">
				<Ressources></Ressources>
			</el-tab-pane>
			<el-tab-pane label="Import/Export">
				<Importation></Importation>
			</el-tab-pane>
		</el-tabs>
	</div>
</template>

<script>
import script from '../../../script/planning-script'
import Ressources from './Ressources'
import Importation from './Importation'
export default {
	data () {
		return {

		}
	},
	methods: {

	},
	components:{
		Ressources,
		Importation
	},
	mounted() {

	}
}
</script>

<style>

</style>