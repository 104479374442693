<template>
  <div>
  </div>
</template>

<script>
import script from '../script/script.js';

export default {
  data() {
    return {
      post: {},
    }
  },
  mounted:function(){
      this.isConnect();

  },
  methods: {
    disconnect: script.disconnect,
    isConnect: script.isConnect
  }
  
}
</script>

<style>

</style>
          