<template>
	<div>
		<el-button @click="clearFilter">Effacer tous les filtres</el-button>
		<el-table ref="searchTable" :data="events" style="width: 100%">
			<el-table-column prop="resourceId" :filters="filterRessources" label="Ressources" :filter-method="filterHandlerRessources">
				<template slot-scope="scope">
					{{personneEvent(scope.row.resourceId)}}
				</template>
			</el-table-column>
			<el-table-column prop="details.nomClient" label="Client">
			</el-table-column>
			<el-table-column prop="details.categories" :filters="filterCategories" label="Catégories" :filter-method="filterHandlerCategories">
				<template slot-scope="scope">
					<div v-for="(item,key) in scope.row.details.categories" :key="key">
						{{ getCategorie(item) }}
					</div> 
				</template>
			</el-table-column>
			<el-table-column prop="details.numStock" label="Numéro de stock">
			</el-table-column>
			<el-table-column prop="start" label="Numéro de semaine" :filters="filterSemaines" :filter-method="filterHandlerSemaines">
				<template scope="scope">
					{{getWeekNumber(scope.row.start)}}
				</template>
			</el-table-column>
		</el-table>
	</div>
</template>

<script>
import moment from "moment"
import scriptPlanning from '../../../script/planning-script'
export default {
	data () {
		return {
			filterCategories:[
				{
					value: 'sav',
					text: 'sav'
				},
				{
					value: 'demos',
					text: 'demos'
				},
				{
					value: 'receptionsTechniques',
					text: 'receptionsTechniques'
				},
				{
					value: 'miseEnService',
					text: 'miseEnService'
				},
				{
					value: 'formation',
					text: 'formation'
				},
				{
					value: 'vae',
					text: 'vae'
				},
				{
					value: 'absence',
					text: 'absence'
				},
				{
					value: 'conges',
					text: 'conges'
				},
				{
					value: 'ferie',
					text: 'ferie'
				},
				{
					value: 'chargement',
					text: 'chargement'
				},
				{
					value: 'dechargement',
					text: 'dechargement'
				},
				{
					value: 'preparationMachine',
					text: 'preparationMachine'
				},
				{
					value: 'formationPI',
					text: 'formationPI'
				},
				{
					value: 'rdvCom',
					text: 'rdvCom'
				},
				{
					value: 'reunion',
					text: 'reunion'
				},
				{
					value: 'reglementAttente',
					text: 'reglementAttente'
				},
				{
					value: 'enAttenteDeLivraison',
					text: 'enAttenteDeLivraison'
				}
			],
			nbResult:"",
		}
	},
	mounted(){
	},
	computed:{
		ressources(){
			return this.$store.state.ressources
		},
		events(){
			let eventsFound = this.$store.state.events.filter(event => event.details.nomClient.toLowerCase().search(this.search.toLowerCase()) > -1 || this.personneEvent(event.resourceId).toLowerCase().search(this.search.toLowerCase()) > -1 || event.details.numStock.toLowerCase().search(this.search.toLowerCase()) > -1)
			this.nbResult = eventsFound.length
			return eventsFound
		},
		filterRessources(){
			let filter = []
			this.$store.state.ressources.forEach(ressource => {
				filter.push({text: ressource.title, value: ressource.id})	
			})
			return filter
		},
		filterSemaines(){
			let semaines = []
			for(var i = 1; i < 54; i++){
				semaines.push({
					value: i,
					text: "Semaine n° "+i
				})
			}
			return semaines
		}
	},
	props:{
		search: Object
	},
	methods:{
		filterHandlerCategories(value, row) {
			return row.details.categories.includes(value)
		},
		filterHandlerRessources(value, row) {
			return row.details.personneAffecte.includes(value)
		},
		filterHandlerSemaines(value, row) {
			let find = false
			if(this.getWeekNumber(row.start) == value){
				find = true
			}
			return find
		},
		clearFilter() {
			this.$refs.searchTable.clearFilter()
		},
		personneEvent(id){
			let personne = this.$store.state.ressources.filter(ressource => ressource.id == id)
			return personne[0].memberDetail.firstname + " " + personne[0].memberDetail.lastname
		},
		getTime(time){
			let timeMoment = moment(time)
			return timeMoment.format('L') + ' ' + timeMoment.format('LT')
		},
		getWeekNumber(time){
			let timeMoment = moment(time)
			return timeMoment.week()
		},
		getCategorie(cat){
			let categories = [
				{
					value: 'sav',
					label: 'SAV'
				},
				{
					value: 'demos',
					label: 'Démos'
				},
				{
					value: 'receptionsTechniques',
					label: 'Réceptions techniques'
				},
				{
					value: 'miseEnService',
					label: 'Mise en service'
				},
				{
					value: 'formation',
					label: 'Formation'
				},
				{
					value: 'vae',
					label: 'VAE (Ventes Aux Enchères)'
				},
				{
					value: 'absence',
					label: 'Absence'
				},
				{
					value: 'conges',
					label: 'Congés'
				},
				{
					value: 'ferie',
					label: 'Fériés si pas automatiques'
				},
				{
					value: 'chargement',
					label: 'Chargement'
				},
				{
					value: 'dechargement',
					label: 'Déchargement'
				},
				{
					value: 'preparationMachine',
					label: 'Préparation des machines'
				},
				{
					value: 'formationPI',
					label: 'Formation du personnel interne'
				},
				{
					value: 'rdvCom',
					label: 'RDV commercial'
				},
				{
					value: 'reunion',
					label: 'Réunion'
				},
				{
					value: 'reglementAttente',
					label: 'Règlement en attente'
				},
				{
					value: 'enAttenteDeLivraison',
					label: 'En attente de livraison de pièces détachées'
				}
			]

			let found = categories.filter(categorie => categorie.value == cat)
			return found[0].label
		}
	}
}
</script>

<style>

</style>