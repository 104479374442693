<template>
	<div>
		<el-tabs tab-position="left">
			<el-tab-pane v-if='userType == "admin"' label="Ajouter">
				<el-form ref="form" :rules="rules" :model="formUser">
					<el-form-item label="Nom">
						<el-input v-model="formUser.prenom"></el-input>
					</el-form-item>

					<el-form-item label="Prénom">
						<el-input v-model="formUser.nom"></el-input>
					</el-form-item>

					<el-form-item label="Email">
						<el-input v-model="formUser.email"></el-input>
					</el-form-item>

					<el-form-item label="Login">
						<el-input v-model="formUser.login"></el-input>
					</el-form-item>

					<el-form-item label="Mot de passe">
						<el-input v-model="formUser.password"></el-input>
					</el-form-item>

					<el-form-item label="Groupe">
						<el-select v-model="formUser.groupe" filterable collapse-tags style="margin-left: 20px;" placeholder="Choisir">
							<el-option v-for="item in optionsGroupe" :key="item.value" :label="item.label" :value="item.value">
							</el-option>
						</el-select>
					</el-form-item>
					
					<el-form-item label="Site">
						<el-select v-model="formUser.site" filterable collapse-tags style="margin-left: 20px;" placeholder="Choisir">
							<el-option v-for="item in optionsSite" :key="item.value" :label="item.label" :value="item.value">
							</el-option>
						</el-select>
					</el-form-item>

					<el-form-item>
						<el-button type="primary" @click="addRessource(formUser)">Ajouter la ressource</el-button>
						<el-button>Annuler</el-button>
					</el-form-item>
				</el-form>
			</el-tab-pane>
			<el-tab-pane label="Liste">
				<el-table :data="ressources" style="width: 100%">
					<el-table-column label="Nom" prop="memberDetail.lastname">
					</el-table-column>

					<el-table-column label="Prénom" prop="memberDetail.firstname">
					</el-table-column>

					<el-table-column label="Groupe" prop="memberDetail.groupe.name" :filters="filterGroupe" :filter-method="filterHandlerGroupe">
					</el-table-column>
					
					<el-table-column label="Site" prop="memberDetail.site">
					</el-table-column>
					
					<el-table-column align="right">
						<template v-if='userType == "admin"' slot-scope="scope">
							<el-button size="mini" type="danger" @click="handleDelete(scope.row.memberDetail.id)">Supprimer</el-button>
						</template>
					</el-table-column>
				</el-table>
			</el-tab-pane>
		</el-tabs>
	</div>
</template>

<script>
import script from '../../../script/planning-script'
export default {
	data () {
		return {
			formUser:{
				prenom:"",
				nom:"",
				email:"",
				login:"",
				groupe: "",
				password:"",
				site:""
			},
			optionsSite:[
				{
					label:"Leers",
					value:"Leers"
				},
				{
					label:"Roissy",
					value:"Roissy"
				},
				{
					label:"Meyzieu",
					value:"Meyzieu"
				}
			],
			optionsGroupe:[],
			search:'',
			filterGroupe:[
				{
					text: 'Équipe technique',
					value: '5'
				},
				{
					text: 'Équipe commerciale',
					value: '6'
				},
				{
					text: 'Équipe administrative',
					value: '8'
				}
			],
			rules: {
				prenom: [
					{ required: true, message: 'Nom de client obligatoire', trigger: 'blur' }
				],
				groupe: [
					{ required: true, message: 'Affectation de personne obligatoire', trigger: 'change' }
				],
				site: [
					{ required: true, message: 'Affectation de personne obligatoire', trigger: 'change' }
				],
				login: [
					{ required: true, message: 'Affectation de personne obligatoire', trigger: 'blur' }
				],
				password: [
					{required: true, message: 'Catégorie obligatoire', trigger: 'blur' }
				]
			}
		}
	},
	methods: {
		loadGroups: script.loadGroups,
		createRessource: script.createRessource,
		loadRessource: script.loadRessource,
		deleteRessource: script.deleteRessource,
		handleDelete(id){
			if(window.confirm("Voulez-vous supprimer cette ressource ?")){
				this.deleteRessource(id)
			}
		},
		addRessource(formUser){
			this.$refs["form"].validate((valid) => {
				if (valid) {
					this.createRessource(formUser)
					this.$refs["form"].resetFields()
				} else {
					return false;
				}
			})
		},
		filterHandlerGroupe(value, row){
			let find = false
			if(row.groupe.idGroupe == value){
				find = true
			}
			return find
		}
	},
	components:{
	},
	mounted() {
		this.loadGroups()
		this.loadRessource()
	},
	computed:{
		ressources(){
			return this.$store.state.ressources
		},
		userType(){
			return this.$store.state.info.type
		}
	}
}
</script>

<style>

</style>